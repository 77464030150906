import React, { useContext, useEffect, useState } from "react"
import { Row, Col, Modal, Label, Input, Form } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import axios from "axios";
import AppConfig from "../../constants/config";
import * as Yup from "yup";
import { object, string, number, date, array, boolean } from "yup";
import { useFormik } from "formik";
import toast, { useToaster } from 'react-hot-toast';
import { AuthContext } from "context/authContext";

const EditNonAccounting = (prop) => {
    const [leaseDraftData, setLeaseDraftData] = useState('')
    const { editOpen, toggleEdit, lease_id} = prop
    const [loading, setLoading] = useState(false);
    const [currencyData, setCurrencyData] = useState([]);
    const {mode, userData} = useContext(AuthContext)
    const [clientData, setClientsData] = useState([]);
    const [clientId, setClientId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [client, setClient] = useState('');
    const [changeClientName, setChangeClientName] = useState(false);
    
    const handleSubmit = async(values) => {
        setLoading(true)
        try {
            values.lease_id = lease_id
            values.mode = mode ? mode : 'lessee'
            const {data} = await axios.post(`${AppConfig.baseUrl}/lease/editNonAccountingLease`,values, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success('Lease details updated successfully ');
                toggleEdit();
                prop.refreshData();
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const changeClient = async() => {
        setLoading(true)
        try {
            let values = {lease_id : lease_id, mode : (mode ? mode : 'lessee'), client_id : client }
            const {data} = await axios.post(`${AppConfig.baseUrl}/lease/change_client`,values, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success('Client changed successfully ');
                setClientId(client);
                setChangeClientName(true);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false) 
        setModalOpen(false)
    }

    const getLeaseDraftData = async()=>{
        setLoading(true)
        try {
            let {data} = await axios.get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${lease_id}&mode=${ mode ? mode : 'lessee' }`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if(data.error) {
                toast.error(data.title || 'Lease draft not found');
            } else {
                setLeaseDraftData(data.data.lease)
            }
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    const getClientsData = async() =>{
        setClientsData([]);
        setLoading(true);
        try {
          const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&access_type=full_access&client_type=${mode || "lessee"}`,{
            headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
            setClientsData(data.clientData || []);

          }
          else{
              throw new Error(data.title);
          }
        } catch (error) {
          toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    useEffect(() => {
    if(userData && userData.id){
        getClientsData({});
    }
    }, [userData, mode])

    useEffect(() => {
        if(leaseDraftData && leaseDraftData.clientId){
            setClientId(leaseDraftData.clientId);
        }
    }, [leaseDraftData])

    useEffect(() =>{
        if(leaseDraftData){
            formik.setValues({
                leaseName: leaseDraftData ? leaseDraftData.leaseName : '',
                type: leaseDraftData ? leaseDraftData.type : 'Office',
                assetDisc: leaseDraftData ? leaseDraftData.assetDisc : '',
                assetTypeValue: leaseDraftData ? leaseDraftData.assetTypeValue : '',
                assetId: leaseDraftData ? leaseDraftData.assetId : '',
                address1: leaseDraftData ? leaseDraftData.address1 : '',
                address2: leaseDraftData ? leaseDraftData.address2 : '',
                city: leaseDraftData ? leaseDraftData.city : '',
                state: leaseDraftData ? leaseDraftData.state : '',
                zipcode: leaseDraftData ? leaseDraftData.zipcode : '',
                lessee_or_lessor_name: leaseDraftData ? leaseDraftData.lessee_or_lessor_name : '',
                lessee_or_lessor_email: leaseDraftData ? leaseDraftData.lessee_or_lessor_email : '',
                lessee_or_lessor_address: leaseDraftData ? leaseDraftData.lessee_or_lessor_address : '',
            })
        }
    },[leaseDraftData])

    const getCurrencyData = async(filter) =>{
        setCurrencyData([]);
        setLoading(true);
        try {
          const {data} = await axios.get(`${AppConfig.baseUrl}/currency/listall`,{
            headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
            setCurrencyData(data.data || []);
          }
          else{
              throw new Error(data.title);
          }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() =>{
        if(lease_id){
            getCurrencyData()
            getLeaseDraftData()
        }else{
            setCurrencyData([])
            setLeaseDraftData('')
        }
    },[lease_id])

    useEffect(() => {
        if(currencyData && currencyData.length >0 && formik.values.currency == 'USD'){
            const cdata = currencyData.filter(c => c.code == 'USD')
            formik.setFieldValue("currency",cdata[0]._id)
        }
      }, [currencyData]) 

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            leaseName: leaseDraftData ? leaseDraftData.leaseName : '',
            type: leaseDraftData ? leaseDraftData.type : 'Office',
            assetDisc: leaseDraftData ? leaseDraftData.assetDisc : '',
            assetTypeValue: leaseDraftData ? leaseDraftData.assetTypeValue : '',
            assetId: leaseDraftData ? leaseDraftData.assetId : '',
            address1: leaseDraftData ? leaseDraftData.address1 : '',
            address2: leaseDraftData ? leaseDraftData.address2 : '',
            city: leaseDraftData ? leaseDraftData.city : '',
            state: leaseDraftData ? leaseDraftData.state : '',
            zipcode: leaseDraftData ? leaseDraftData.zipcode : '',
            currency: leaseDraftData ? leaseDraftData.currency : 'USD',
            lessee_or_lessor_name: leaseDraftData ? leaseDraftData.lessee_or_lessor_name : '',
            lessee_or_lessor_email: leaseDraftData ? leaseDraftData.lessee_or_lessor_email : '',
            lessee_or_lessor_address: leaseDraftData ? leaseDraftData.lessee_or_lessor_address : '',
        },
        validationSchema: Yup.object({            
            leaseName: string().required("Please enter Lease name"),    
            state: string().required("Please select your state"),
            type: string().required("Please select your type")           
        }),
        onSubmit: handleSubmit
    });

    return (
        <Modal
            isOpen={editOpen}
            toggle={() => {
                toggleEdit();
                if(changeClientName)  prop.refreshData();
            }}
            size="lg"
            centered
        >
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Edit Non Accounting Lease</h5>
                <button
                    type="button"
                    onClick={() => {
                        toggleEdit();
                        if(changeClientName)  prop.refreshData();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <Form>
                        <Row>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="client">
                                        Select client
                                    </Label>
                                    <div className="input-group-append">
                                        <select className="form-control arrows" id='client'
                                        onChange={(e)=> {
                                            if(e.target.value != clientId) setClient(e.target.value); setModalOpen(true)                                            
                                        }}
                                        value={clientId} onBlur={() => formik.setFieldTouched("client")} >
                                        { clientData && clientData.map((data,i)=>
                                            <option key={i+data.client_id.client} value={data.client_id._id} >{data.client_id.client}</option>
                                        )}
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Row className="mt-4">
                                    <Col sm="3">
                                        <button
                                            type="button"
                                            className="btn rounded-circle avatar-md bg-lbusers mt-0 ms-0 text-align-center"
                                        >
                                            {
                                                formik.values.type === 'Vehicle' ? <i className='bx bx-car text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                                : (formik.values.type === 'Building' || formik.values.type === 'Office') ? <i className='bx bx-buildings text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                                : formik.values.type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                                : formik.values.type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '250%', padding: '0' }}></i>
                                                : formik.values.type === 'Others' ? <i className='bx bx-customize text-white' style={{ fontSize: '250%', padding: '0' }}></i> : ''
                                            }
                                        </button>
                                    </Col>
                                    <Col sm="9">
                                        <div>
                                            <Label for="assetType">
                                                Asset type <span className="text-danger">*</span>
                                            </Label>
                                            <select className="form-control arrows" 
                                            // onChange={(e)=>{setSelectedAssetType(e.target.value)}}
                                            onChange={(e)=>{formik.setFieldValue("type", e.target.value);formik.setFieldValue("assetTypeValue", '');}}
                                            value={formik.values.type} onBlur={() => formik.setFieldTouched("type")}>
                                                <option value="Vehicle">Vehicle</option>
                                                <option value="Building">Building</option>
                                                <option value="Land">Land</option>
                                                <option value="Equipment">Equipment</option>
                                                <option value="Office">Office</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="leaseName">
                                        Lease name <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="leaseName"
                                        onChange={formik.handleChange}
                                        onBlur={() => formik.setFieldTouched("leaseName")}
                                        value={formik.values.leaseName}
                                    />                           
                                    {formik.touched.leaseName && formik.errors.leaseName && (
                                        <span className='text-danger'>{formik.touched.leaseName && formik.errors.leaseName}</span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="assetDisc">
                                        Asset description
                                    </Label>
                                    <Input
                                        type="textarea"
                                        rows={6}
                                        className="form-control"
                                        id="assetDisc"
                                        // value={prop.type === 'Edit' || prop.type === 'clone' ? "This is asset description":''}
                                        onChange={formik.handleChange}
                                        value={formik.values.assetDisc}
                                    />                           
                                    {/* {formik.touched.assetDisc && formik.errors.assetDisc && (
                                        <span className='text-danger'>{formik.touched.assetDisc && formik.errors.assetDisc}</span>
                                    )} */}
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="assetId">
                                        Serial / VIN / Asset ID
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="assetId"
                                        // value={prop.type === 'Edit' || prop.type === 'clone' ?"1":''}
                                        onChange={formik.handleChange}
                                        value={formik.values.assetId}
                                    />
                                </div>
                                <div className="mt-4">
                                        <Label for="manufacturer">
                                            { formik.values.type === 'Vehicle' ? 'Manufacturer'
                                                :(formik.values.type === 'Building' || formik.values.type === 'Office') ?'Sq. Footage'
                                                :formik.values.type === 'Equipment' ? 'Manufacturer'
                                                :formik.values.type === 'Land' ? 'Sq. Footage/Acreage'
                                                :formik.values.type === 'Others'? 'Additional Info'
                                                :''
                                            }
                                        </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="assetTypeValue"
                                        // value={prop.type === 'Edit' || prop.type === 'clone' ? "LodhaFoundation":''}
                                        onChange={formik.handleChange}
                                        value={formik.values.assetTypeValue}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="street">
                                        Address Line 1
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="address1"
                                        // value={prop.type === 'Edit' || prop.type === 'clone' ? "Victoria road" :''}
                                        onChange={formik.handleChange}
                                        value={formik.values.address1}
                                    />  
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="street">
                                        Address Line 2
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="address2"
                                        // value={prop.type === 'Edit' || prop.type === 'clone' ? "Victoria road" :''}
                                        onChange={formik.handleChange}
                                        value={formik.values.address2}
                                    />  
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="city">
                                        City
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        // value={prop.type === 'Edit' || prop.type === 'clone' ? "San Francisco":''}
                                        onChange={formik.handleChange}
                                        value={formik.values.city}
                                    />  
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="state">
                                        State <span className="text-danger">*</span>
                                    </Label>
                                    <select className="form-control arrows" id='state' onChange={(e)=>formik.setFieldValue("state", e.target.value)}
                                        value={formik.values.state} onBlur={() => formik.setFieldTouched("state")}>
                                            <option value=''>Select state</option>
                                            <option value='AL'>AL</option>
                                            <option value='AK'>AK</option>
                                            <option value='AZ'>AZ</option>
                                            <option value='AR'>AR</option>
                                            <option value='CA'>CA</option>
                                            <option value='CO'>CO</option>
                                            <option value='CT'>CT</option>
                                            <option value='DE'>DE</option>
                                            <option value='DC'>DC</option>
                                            <option value='FL'>FL</option>
                                            <option value='GA'>GA</option>
                                            <option value='HI'>HI</option>
                                            <option value='ID'>ID</option>
                                            <option value='IL'>IL</option>
                                            <option value='IN'>IN</option>
                                            <option value='IA'>IA</option>
                                            <option value='KS'>KS</option>
                                            <option value='KY'>KY</option>
                                            <option value='LA'>LA</option>
                                            <option value='ME'>ME</option>
                                            <option value='MD'>MD</option>
                                            <option value='MA'>MA</option>
                                            <option value='MI'>MI</option>
                                            <option value='MN'>MN</option>
                                            <option value='MS'>MS</option>
                                            <option value='MO'>MO</option>
                                            <option value='MT'>MT</option>
                                            <option value='NE'>NE</option>
                                            <option value='NV'>NV</option>
                                            <option value='NH'>NH</option>
                                            <option value='NJ'>NJ</option>
                                            <option value='NM'>NM</option>
                                            <option value='NY'>NY</option>
                                            <option value='NC'>NC</option>
                                            <option value='ND'>ND</option>
                                            <option value='OH'>OH</option>
                                            <option value='OK'>OK</option>
                                            <option value='OR'>OR</option>
                                            <option value='PA'>PA</option>
                                            <option value='PR'>PR</option>
                                            <option value='RI'>RI</option>
                                            <option value='SC'>SC</option>
                                            <option value='SD'>SD</option>
                                            <option value='TN'>TN</option>
                                            <option value='TX'>TX</option>
                                            <option value='UT'>UT</option>
                                            <option value='VT'>VT</option>
                                            <option value='VA'>VA</option>
                                            <option value='VI'>VI</option>
                                            <option value='WA'>WA</option>
                                            <option value='WV'>WV</option>
                                            <option value='WI'>WI</option>
                                            <option value='WY'>WY</option>
                                    </select>
                                    {formik.touched.state && formik.errors.state && (
                                        <span className='text-danger'>{formik.touched.state && formik.errors.state}</span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="zipcode">
                                        Zipcode
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="zipcode"
                                        onChange={formik.handleChange}
                                        value={formik.values.zipcode}
                                        // value={prop.type === 'Edit' || prop.type === 'clone' ? "99950":''}
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mt-4">
                                    <Label for="currency">
                                        Select currency
                                    </Label>
                                    <div className="input-group-append">
                                        <select className="form-control arrows" id='currency'
                                        onChange={(e)=> {
                                            formik.setFieldValue(`currency`, e.target.value)
                                        }}
                                        value={formik.values.currency} onBlur={() => formik.setFieldTouched("currency")} >
                                            {
                                                currencyData.map(opt => <option key={opt._id} value={opt._id}>{opt.code}</option> )
                                            }
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="mt-4"><h5>{mode == 'lessor'? 'Lessee details' : 'Lessor details'}</h5></div>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="mt-2">
                                    <Label for="lessee_or_lessor_name">
                                        Name
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="lessee_or_lessor_name"
                                        onChange={formik.handleChange}
                                        value={formik.values.lessee_or_lessor_name}
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mt-2">
                                    <Label for="lessee_or_lessor_email">
                                        Email
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="lessee_or_lessor_email"
                                        onChange={formik.handleChange}
                                        value={formik.values.lessee_or_lessor_email}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mt-2">
                                    <Label for="lessee_or_lessor_address">
                                        Address
                                    </Label>
                                    <Input
                                        type="textarea"
                                        rows={1}
                                        className="form-control"
                                        id="lessee_or_lessor_address"
                                        onChange={formik.handleChange}
                                        value={formik.values.lessee_or_lessor_address}
                                    />      
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    setModalOpen(!modalOpen);
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Change client</h5>
                    <button
                        type="button"
                        onClick={() => {
                            formik.setFieldValue("client",clientId);
                            setModalOpen(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="mb-3">
                            Are you sure you want to change client ?
                        </div>
                        <div className="modal-footer mt-3">
                            <button
                                type="button"
                                className="btn btn-lbusers"
                                onClick={() =>{
                                    formik.setFieldValue("client",clientId)
                                    setModalOpen(false)
                                }}
                                disabled={loading} 
                            >
                                {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                No
                            </button>
                            <button type="button" className="btn btn-lbusers" onClick={() => { changeClient();}} disabled={loading} >
                                {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </React.Fragment>
        </Modal>
    )
}


export default EditNonAccounting