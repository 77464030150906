import React, { useState, useEffect, useContext } from "react"
import {Helmet} from "react-helmet";
import { Card, CardBody, Col, Container, Row, Alert, Spinner } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "components/Common/Breadcrumbclientdetails"
import Filter from "pages/Filter";
import { AuthContext } from "context/authContext";
import toast, { useToaster } from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ExcelJS from "exceljs";
import Layout from "components/VerticalLayout";

const Disclosure = () => {
    const location = useLocation();
    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    const { userData, reloadProcessingSearch, isProcessingSearch, mode } = useContext(AuthContext)
    const [filter, setFilter] = useState(true);
    const [loading, setLoading] = useState(false);
    const [lesseeClients, setLesseeClients] = useState([]);
    const [lessorClients, setLessorClients] = useState([]);
    const [disclosureData, setDisclosureData] = useState({});
    const [loadingDisclosures, setLoadingDisclosures] = useState(true);
    const [searchResult, setSearchResult] = useState({});
    const [dateType, setDateType] = useState('exactDate');
    const [accountType, setAccountType] = useState("");
  
    const getClientsData = async(filter) =>{
      setLesseeClients([]);
      setLessorClients([]);
      setLoading(true);
      try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&access_type=${filter.access_type || ""}&search=${filter.search || ""}`,{
          headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
            const lessee = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessee') : []
            setLesseeClients(lessee);
            const lessor = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessor') : []
            setLessorClients(lessor);
        }
        else{
            throw new Error(data.title);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
      }
      setLoading(false)
    }
  
    const getDisclosureData = async(start, end, leases,clients, weekType, dayType, monthType, yearDate, dateType) =>{
        setLoadingDisclosures(true);
        setDisclosureData([]);
        try {
            const body = {
                start_date: moment(start).format("YYYY-MM-DD"),
                end_date: moment(end).format("YYYY-MM-DD"),
                lease_ids: leases,
                clients,
                dayType,monthType,weekType,yearDate,dateType,
                mode: mode ? mode : 'lessee'
            }
            const {data} = await axios.post(`${AppConfig.baseUrl}/disclosures/get_disclosures`,body, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                getSearchResult(data.searchData._id);
                reloadProcessingSearch();
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            setLoadingDisclosures(false)
        }
    }
  
    const getSearchResult = async(id, type) => {
        setFilter(false)
        setDisclosureData([]);
        setLoadingDisclosures(true);
        try {
          const {data} = await axios.get(`${AppConfig.baseUrl}/search_query?id=${id}&type=${type}&mode=${mode ? mode : 'lessee'}`,{
            headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
            setSearchResult(data.searchResult)
            setDisclosureData(data.searchResult && data.searchResult.result ? data.searchResult.result : {});
          }
          else{
            throw new Error(data.title);
          }
        } catch (error) {
          toast.error(error.message || 'Something went wrong');
        }
        setLoadingDisclosures(false);
    }

    useEffect(()=>{
        if(sp.get("id")){
          setFilter(false);
          getSearchResult(sp.get("id"), 'delete')
        }
      },[location.search])

    useEffect(()=>{
        if(searchResult && searchResult._id && !isProcessingSearch){
          getSearchResult(searchResult._id, 'delete')
        }
    },[isProcessingSearch])

    useEffect(()=>{
    if(mode && !filter){
        setFilter(true)
        setDisclosureData([]);
    }
    },[mode])
    
    const handleChangeType = (e) => {
        setDateType(e.target.value)
    }

    useEffect(() => {
      if(userData && userData.id){
        getClientsData({});
      }
    }, [userData])

    const flip = async(selectedLeases, startDate, endDate, jeType, dateType, weekType, dayType, monthType, yearDate) => {
        let uniqueClients = [];
        let ids = []
        for(const lease of selectedLeases){
            ids.push(lease._id)
            const client = lease.client_id
            const index = uniqueClients.findIndex(v => v === lease.client_id._id)
            if(index === -1){
                uniqueClients.push(client._id);
            }
        }
        setFilter(false);
        getDisclosureData(startDate, endDate, ids, uniqueClients, weekType, dayType, monthType, yearDate, dateType);
    }

    const downloadCSV = () => {
        var ExcelJSWorkbook = new ExcelJS.Workbook();
        var worksheet = ExcelJSWorkbook.addWorksheet("Disclosures");
        if(mode == "lessor"){
            worksheet.getColumn("A").width = 60
            worksheet.getColumn("B").width = 20
            worksheet.getColumn("C").width = 20
            worksheet.getRow("2").height = 30
            worksheet.getRow("9").height = 30

            worksheet.mergeCells("A2:C2");
            const customCell = worksheet.getCell("A2");
            customCell.font = {
            size: 16,
            bold: true,
            color:{argb:'FFFFFFFF'}
            };
            customCell.fill = {
                type: 'pattern',
                pattern:'solid',
                bgColor:{argb:'FF0000FF'}
            };
            customCell.value = "Income Statement";

            worksheet.mergeCells("A4:C4");
            const customCell2 = worksheet.getCell("A4");
            customCell2.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell2.value = `${ mode == "lessor" ? '' : 'Financing' } Lease ${ mode == "lessor" ? "Revenue" : "Costs"}:`;

            worksheet.mergeCells("A5:B5");
            worksheet.getCell("A5").value = `${ mode == "lessor" ? "Lease revenue" : "Amortization"}`
            worksheet.getCell("C5").value = disclosureData && disclosureData.financing && disclosureData.financing.amortExp && disclosureData.financing.amortExp != 'NA' ? Number(disclosureData.financing.amortExp.replace(/,/g, '')) : 'NA'

            worksheet.mergeCells("A6:B6");
            worksheet.getCell("A6").value = `${ mode == "lessor" ? "Interest Revenue" : "Interest on lease liabilities"}`
            worksheet.getCell("C6").value = disclosureData && disclosureData.financing && disclosureData.financing.intExp && disclosureData.financing.intExp != 'NA' ? Number(disclosureData.financing.intExp.replace(/,/g, '')) : 'NA'

            worksheet.mergeCells("A7:B7");
            worksheet.getCell("A7").value = `${ mode == "lessor" ? "Variable Lease Revenue" : "Variable Lease Costs"} - ${ mode == "lessor" ? '' : 'Financing' } Leases`
            worksheet.getCell("C7").value = disclosureData && disclosureData.financing && disclosureData.financing.variableCost && disclosureData.financing.variableCost != 'NA' ? Number(disclosureData.financing.variableCost.replace(/,/g, '')) : "NA"

            worksheet.mergeCells("A9:C9");
            const customCell3 = worksheet.getCell("A9");
            customCell3.font = {
            size: 16,
            bold: true,
            color:{argb:'FFFFFFFF'}
            };
            customCell3.fill = {
                type: 'pattern',
                pattern:'solid',
                bgColor:{argb:'F08080'}
            };
            customCell3.value = "Balance Sheet (as of Date)";

            worksheet.mergeCells("A11:C11");
            const customCell5 = worksheet.getCell("A11");
            customCell5.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell5.value = `${ mode == "lessor" ? '' : 'Financing' } Leases`;

            worksheet.mergeCells("A12:B12");
            worksheet.getCell("A12").value = `${ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} ${ mode == "lessor" ? '' : - 'Financing' }`
            worksheet.getCell("C12").value = disclosureData && disclosureData.financing && disclosureData.financing.rouAtCommensement && disclosureData.financing.rouAtCommensement != 'NA' ? Number(disclosureData.financing.rouAtCommensement.replace(/,/g, '')) : 0

            worksheet.mergeCells("A13:B13");
            worksheet.getCell("A13").value = `${ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} - Accumulated ${ mode == "lessor" ? "Lease revenue" : "Amortization"}`
            worksheet.getCell("C13").value = disclosureData && disclosureData.financing && disclosureData.financing.accAmort && disclosureData.financing.accAmort != 'NA' ? Number(disclosureData.financing.accAmort.replace(/,/g, '')) : 0

            worksheet.mergeCells("A14:B14");
            worksheet.getCell("A14").value = `Total ${ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} ${ mode == "lessor" ? '' : - 'Financing' }`
            worksheet.getCell("C14").value = disclosureData && disclosureData.financing && disclosureData.financing.flRouEnding && disclosureData.financing.flRouEnding != 'NA' ? Number(disclosureData.financing.flRouEnding.replace(/,/g, '')) : 0

            worksheet.mergeCells("A16:B16");
            worksheet.getCell("A16").value = `Current ${ mode == "lessor" ? '' : 'Financing' } Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C16").value = disclosureData && disclosureData.financing && disclosureData.financing.totalPV && disclosureData.financing.totalPV != 'NA' ? Number(disclosureData.financing.totalPV.replace(/,/g, '')) : 0

            worksheet.mergeCells("A17:B17");
            worksheet.getCell("A17").value = `Noncurrent ${ mode == "lessor" ? '' : 'Financing' } Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C17").value = disclosureData && disclosureData.financing && disclosureData.financing.nonCurrentLiability && disclosureData.financing.nonCurrentLiability != 'NA' ? Number(disclosureData.financing.nonCurrentLiability.replace(/,/g, '')) : 0

            worksheet.mergeCells("A18:B18");
            worksheet.getCell("A18").value = `Total ${ mode == "lessor" ? '' : 'Financing' } Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C18").value = disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiabilityEnd && disclosureData.financing.flLeaseLiabilityEnd != 'NA' ? Number(disclosureData.financing.flLeaseLiabilityEnd.replace(/,/g, '')) : 0

            worksheet.mergeCells("A20:B20");
            worksheet.getCell("A20").value = "Weighted Average Remaining Lease Term"
            worksheet.getCell("C20").value = disclosureData && disclosureData.financing && disclosureData.financing.avgTermF && disclosureData.financing.avgTermF != 'NA' ? Number(disclosureData.financing.avgTermF.replace(/,/g, '')) : 0

            worksheet.mergeCells("A21:B21");
            worksheet.getCell("A21").value = "Weighted Average Discount Rate"
            worksheet.getCell("C21").value = disclosureData && disclosureData.financing && disclosureData.financing.avgRateF && disclosureData.financing.avgRateF != 'NA' ? Number(disclosureData.financing.avgRateF.replace(/,/g, '')) : 0

            worksheet.mergeCells("A23:C23");
            const customCell6 = worksheet.getCell("A23");
            customCell6.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell6.value = `Maturity of Lease ${ mode == "lessor" ? "Receivables" : "Liabilities"} :`;
            worksheet.addRow(["Year Ending [As of Date]", '', `${ mode == "lessor" ? '' : 'Financing' } Leases`]);
            worksheet.mergeCells('A24','B24')
            worksheet.getCell("A24").font = {size: 10, bold: true};
            worksheet.getCell("C24").font = {size: 10, bold: true};            
            {
                disclosureData && disclosureData.olMaturity && Object.keys(disclosureData.olMaturity).length > 0 && Object.keys(disclosureData.olMaturity).map((year, key) => {
                    if(year !== 'thereafter'){
                        worksheet.addRow([`Year ${key + 1} (${year}) `, '', Number(disclosureData.flMaturity[year] ? disclosureData.flMaturity[year].replace(/,/g, '') : 0)]);
                    }
                })
            }
            worksheet.addRow(["Thereafter", '', Number(disclosureData && disclosureData.flMaturity && disclosureData.flMaturity.thereafter ? disclosureData.flMaturity.thereafter.replace(/,/g, '') : 0)]);
            worksheet.addRow(["Total Lease Payments", '', Number(disclosureData && disclosureData.financing && disclosureData.financing.totalFlPayment ? disclosureData.financing.totalFlPayment.replace(/,/g, '') : 0)]);
            worksheet.addRow(["","",""])
            worksheet.addRow(["Less Imputed Interest", '', Number(disclosureData && disclosureData.financing && disclosureData.financing.imputedInt ? disclosureData.financing.imputedInt.replace(/,/g, '') : 0)]);
            worksheet.addRow(["Total", '', Number(disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiabilityEnd ? disclosureData.financing.flLeaseLiabilityEnd.replace(/,/g, '') : 0)]);
            worksheet.getCell("A34").font = {size: 10, bold: true};
            worksheet.getCell("B34").font = {size: 10, bold: true};
            worksheet.getCell("C34").font = {size: 10, bold: true};
            worksheet.mergeCells('A25','B25')
            worksheet.mergeCells('A26','B26')
            worksheet.mergeCells('A27','B27')
            worksheet.mergeCells('A28','B28')
            worksheet.mergeCells('A29','B29')
            worksheet.mergeCells('A30','B30')
            worksheet.mergeCells('A31','B31')
            worksheet.mergeCells('A34','B34')
            worksheet.mergeCells('A35','B35')

            worksheet.mergeCells("A37:C37");
            const customCell7 = worksheet.getCell("A37");
            customCell7.font = {
            size: 16,
            bold: true,
            color:{argb:'FFFFFFFF'}
            };
            customCell7.fill = {
                type: 'pattern',
                pattern:'solid',
                bgColor:{argb:'F08080'}
            };
            customCell7.value = "Cash Flow Information";
            worksheet.getRow("37").height = 30

            worksheet.mergeCells("A39:C39");
            const customCell9 = worksheet.getCell("A39");
            customCell9.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell9.value = `${ mode == "lessor" ? '' : 'Financing' } Leases`;

            worksheet.mergeCells("A40:C40");
            const customCell11 = worksheet.getCell("A40");
            customCell11.font = {
                underline: true,
            };
            customCell11.value = "Cash Flow from Operating Activities:";
            worksheet.addRow([`${ mode == "lessor" ? 'Non-cash Lease revenue from Deferred inflow' : 'Financing Lease - Amortization of ROU Asset'}`, "", disclosureData && disclosureData.financing && disclosureData.financing.amortExp && disclosureData.financing.amortExp != 'NA' ? Number(disclosureData.financing.amortExp.replace(/,/g, '')) : 'NA'])
            worksheet.mergeCells("A41:B41");
            worksheet.getCell('A41').alignment = { indent: 2 };            

            worksheet.mergeCells("A42:C42");
            const customCell12 = worksheet.getCell("A42");
            customCell12.font = {
                underline: true,
            };
            customCell12.value = `Cash Flow from ${ mode == "lessor" ? '' : 'Financing' } Activities:`;
            worksheet.addRow([`Principal ${ mode == "lessor" ? 'Receipts' : 'Payment' } of ${ mode == "lessor" ? '' : 'Financing' } Leases`, "", disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiability && disclosureData.financing.flLeaseLiability != 'NA' ? Number(disclosureData.financing.flLeaseLiability.replace(/,/g, '')) : 0])
            worksheet.mergeCells("A43:B43");
            worksheet.getCell('A43').alignment = { indent: 2 };

            worksheet.mergeCells("A45:C45");
            const customCell10 = worksheet.getCell("A45");
            customCell10.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell10.value = "Other Cash Flow Information";

            worksheet.addRow([`Noncash Disclosure - ${ mode == "lessor" ? "Deferred inflow" : "ROU Assets"} obtained in exchange for new ${ mode == "lessor" ? '' : 'Financing' } lease ${ mode == "lessor" ? "Receivables" : "Liabilities"}`, "", disclosureData && disclosureData.financing && disclosureData.financing.initRecognition && disclosureData.financing.initRecognition != 'NA' ? Number(disclosureData.financing.initRecognition.replace(/,/g, '')) : 'NA'])
            worksheet.mergeCells("A46:B46");    
        }else{
            worksheet.getColumn("A").width = 60
            worksheet.getColumn("B").width = 20
            worksheet.getColumn("C").width = 20
            worksheet.getRow("2").height = 30
            worksheet.getRow("17").height = 30

            worksheet.mergeCells("A2:C2");
            const customCell = worksheet.getCell("A2");
            customCell.font = {
              size: 16,
              bold: true,
              color:{argb:'FFFFFFFF'}
            };
            customCell.fill = {
                type: 'pattern',
                pattern:'solid',
                bgColor:{argb:'FF0000FF'}
            };
            customCell.value = "Income Statement";
    
            worksheet.mergeCells("A4:C4");
            const customCell1 = worksheet.getCell("A4");
            customCell1.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell1.value = `Operating Lease ${ mode == "lessor" ? "Revenue" : "Costs"}:`;

            worksheet.mergeCells("A5:B5");
            worksheet.getCell("A5").value = `Operating Lease ${ mode == "lessor" ? "Revenue" : "Costs"}`
            worksheet.getCell("C5").value = disclosureData && disclosureData.operating && disclosureData.operating.opLeaseCosts && disclosureData.operating.opLeaseCosts != 'NA' ? Number(disclosureData.operating.opLeaseCosts.replace(/,/g, '')) : 'NA'

            worksheet.mergeCells("A6:B6");
            worksheet.getCell("A6").value = `${ mode == "lessor" ? "Variable Lease Revenue" : "Variable Lease Costs"} - Operating`
            worksheet.getCell("C6").value = disclosureData && disclosureData.operating && disclosureData.operating.variableCost && disclosureData.operating.variableCost != 'NA' ? Number(disclosureData.operating.variableCost.replace(/,/g, '')) : "NA"
    
            worksheet.mergeCells("A8:C8");
            const customCell2 = worksheet.getCell("A8");
            customCell2.font = {
              size: 12,
              bold: true,
              italic: true
            };
            customCell2.value = `Financing Lease ${ mode == "lessor" ? "Revenue" : "Costs"}:`;
    
            worksheet.mergeCells("A9:B9");
            worksheet.getCell("A9").value = `${ mode == "lessor" ? "Lease revenue" : "Amortization"}`
            worksheet.getCell("C9").value = disclosureData && disclosureData.financing && disclosureData.financing.amortExp && disclosureData.financing.amortExp != 'NA' ? Number(disclosureData.financing.amortExp.replace(/,/g, '')) : 'NA'
    
            worksheet.mergeCells("A10:B10");
            worksheet.getCell("A10").value = `${ mode == "lessor" ? "Interest on Revenue" : "Interest on lease liabilities"}`
            worksheet.getCell("C10").value = disclosureData && disclosureData.financing && disclosureData.financing.intExp && disclosureData.financing.intExp != 'NA' ? Number(disclosureData.financing.intExp.replace(/,/g, '')) : 'NA'
    
            worksheet.mergeCells("A11:B11");
            worksheet.getCell("A11").value = `${ mode == "lessor" ? "Variable Lease Revenue" : "Variable Lease Costs"} - Financing Leases`
            worksheet.getCell("C11").value = disclosureData && disclosureData.financing && disclosureData.financing.variableCost && disclosureData.financing.variableCost != 'NA' ? Number(disclosureData.financing.variableCost.replace(/,/g, '')) : "NA"
    
            // worksheet.mergeCells("A13:C13");
            // const customCell16 = worksheet.getCell("A13");
            // customCell16.font = {
            // size: 12,
            // bold: true,
            // italic: true
            // };
            // customCell16.value = `Short Term Lease Costs:`;

            // worksheet.mergeCells("A14:B14");
            // worksheet.getCell("A14").value = `Short term lease costs`
            // worksheet.getCell("C14").value = disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.shortLeaseCosts && disclosureData.shortTerm.shortLeaseCosts != 'NA' ? Number(disclosureData.shortTerm.shortLeaseCosts.replace(/,/g, '')) : 'NA'

            // worksheet.mergeCells("A15:B15");
            // worksheet.getCell("A15").value = `Variable lease costs`
            // worksheet.getCell("C15").value = disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.shortVariableCost && disclosureData.shortTerm.shortVariableCost != 'NA' ? Number(disclosureData.shortTerm.shortVariableCost.replace(/,/g, '')) : 'NA'

    
            worksheet.mergeCells("A13:C13");
            const customCell3 = worksheet.getCell("A13");
            customCell3.font = {
              size: 16,
              bold: true,
              color:{argb:'FFFFFFFF'}
            };
            customCell3.fill = {
                type: 'pattern',
                pattern:'solid',
                bgColor:{argb:'F08080'}
            };
            customCell3.value = "Balance Sheet (as of Date)";
    
            worksheet.mergeCells("A15:C15");
            const customCell4 = worksheet.getCell("A15");
            customCell4.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell4.value = "Operating Leases";

            worksheet.mergeCells("A16:B16");
            worksheet.getCell("A16").value = `${ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} - Operating`
            worksheet.getCell("C16").value = disclosureData && disclosureData.operating && disclosureData.operating.olRouEnding && disclosureData.operating.olRouEnding != 'NA' ? Number(disclosureData.operating.olRouEnding.replace(/,/g, '')) : 0

            worksheet.mergeCells("A18:B18");
            worksheet.getCell("A18").value = `Current Operating Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C18").value = disclosureData && disclosureData.operating && disclosureData.operating.totalPV && disclosureData.operating.totalPV != 'NA' ? Number(disclosureData.operating.totalPV.replace(/,/g, '')) : 0

            worksheet.mergeCells("A19:B19");
            worksheet.getCell("A19").value = `Noncurrent Operating Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C19").value = disclosureData && disclosureData.operating && disclosureData.operating.nonCurrentLiability && disclosureData.operating.nonCurrentLiability != 'NA' ? Number(disclosureData.operating.nonCurrentLiability.replace(/,/g, '')) : 0

            worksheet.mergeCells("A20:B20");
            worksheet.getCell("A20").value = `Total Operating Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C20").value = disclosureData && disclosureData.operating && disclosureData.operating.olLeaseLiabilityEnd && disclosureData.operating.olLeaseLiabilityEnd != 'NA' ? Number(disclosureData.operating.olLeaseLiabilityEnd.replace(/,/g, '')) : 0

            worksheet.mergeCells("A22:B22");
            worksheet.getCell("A22").value = "Weighted Average Remaining Lease Term"
            worksheet.getCell("C22").value = disclosureData && disclosureData.operating && disclosureData.operating.avgTermOp && disclosureData.operating.avgTermOp != 'NA' ? Number(disclosureData.operating.avgTermOp.replace(/,/g, '')) : 0

            worksheet.mergeCells("A23:B23");
            worksheet.getCell("A23").value = "Weighted Average Discount Rate"
            worksheet.getCell("C23").value = disclosureData && disclosureData.operating && disclosureData.operating.avgRateOp && disclosureData.operating.avgRateOp != 'NA' ? Number(disclosureData.operating.avgRateOp.replace(/,/g, '')) : 0
            
            worksheet.mergeCells("A25:C25");
            const customCell5 = worksheet.getCell("A25");
            customCell5.font = {
              size: 12,
              bold: true,
              italic: true
            };
            customCell5.value = "Financing Leases";
    
            worksheet.mergeCells("A26:B26");
            worksheet.getCell("A26").value = `${ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} - Financing`
            worksheet.getCell("C26").value = disclosureData && disclosureData.financing && disclosureData.financing.rouAtCommensement && disclosureData.financing.rouAtCommensement != 'NA' ? Number(disclosureData.financing.rouAtCommensement.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A27:B27");
            worksheet.getCell("A27").value = `${ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} - Accumulated ${ mode == "lessor" ? "Lease revenue" : "Amortization"}`
            worksheet.getCell("C27").value = disclosureData && disclosureData.financing && disclosureData.financing.accAmort && disclosureData.financing.accAmort != 'NA' ? Number(disclosureData.financing.accAmort.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A28:B28");
            worksheet.getCell("A28").value = `Total ${ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} - Financing`
            worksheet.getCell("C28").value = disclosureData && disclosureData.financing && disclosureData.financing.flRouEnding && disclosureData.financing.flRouEnding != 'NA' ? Number(disclosureData.financing.flRouEnding.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A30:B30");
            worksheet.getCell("A30").value = `Current Financing Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C30").value = disclosureData && disclosureData.financing && disclosureData.financing.totalPV && disclosureData.financing.totalPV != 'NA' ? Number(disclosureData.financing.totalPV.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A31:B31");
            worksheet.getCell("A31").value = `Noncurrent Financing Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C31").value = disclosureData && disclosureData.financing && disclosureData.financing.nonCurrentLiability && disclosureData.financing.nonCurrentLiability != 'NA' ? Number(disclosureData.financing.nonCurrentLiability.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A32:B32");
            worksheet.getCell("A32").value = `Total Financing Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`
            worksheet.getCell("C32").value = disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiabilityEnd && disclosureData.financing.flLeaseLiabilityEnd != 'NA' ? Number(disclosureData.financing.flLeaseLiabilityEnd.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A34:B34");
            worksheet.getCell("A34").value = "Weighted Average Remaining Lease Term"
            worksheet.getCell("C34").value = disclosureData && disclosureData.financing && disclosureData.financing.avgTermF && disclosureData.financing.avgTermF != 'NA' ? Number(disclosureData.financing.avgTermF.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A35:B35");
            worksheet.getCell("A35").value = "Weighted Average Discount Rate"
            worksheet.getCell("C35").value = disclosureData && disclosureData.financing && disclosureData.financing.avgRateF && disclosureData.financing.avgRateF != 'NA' ? Number(disclosureData.financing.avgRateF.replace(/,/g, '')) : 0
    
            worksheet.mergeCells("A37:C37");
            const customCell6 = worksheet.getCell("A37");
            customCell6.font = {
              size: 12,
              bold: true,
              italic: true
            };
            customCell6.value = `Maturity of Lease ${ mode == "lessor" ? "Receivables" : "Liabilities"} :`;
            worksheet.addRow(["Year Ending [As of Date]",  mode != "lessor" && 'Operating Leases', "Financing Leases"]);
            worksheet.getCell("A38").font = {size: 10, bold: true};
            worksheet.getCell("B38").font = {size: 10, bold: true};
            worksheet.getCell("C38").font = {size: 10, bold: true};
            {
                disclosureData && disclosureData.olMaturity && Object.keys(disclosureData.olMaturity).length > 0 && Object.keys(disclosureData.olMaturity).map((year, key) => {
                    if(year !== 'thereafter'){
                        worksheet.addRow([`Year ${key + 1} (${year}) `, Number( mode != "lessor" && disclosureData.olMaturity[year] ? disclosureData.olMaturity[year].replace(/,/g, '') : 0), Number(disclosureData.flMaturity[year] ? disclosureData.flMaturity[year].replace(/,/g, '') : 0)]);
                    }
                })
            }
            worksheet.addRow(["Thereafter", Number( mode != "lessor" && disclosureData && disclosureData.olMaturity && disclosureData.olMaturity.thereafter ? disclosureData.olMaturity.thereafter.replace(/,/g, '') : 0), Number(disclosureData && disclosureData.flMaturity && disclosureData.flMaturity.thereafter ? disclosureData.flMaturity.thereafter.replace(/,/g, '') : 0)]);
            worksheet.addRow(["Total Lease Payments", Number( mode != "lessor" && disclosureData && disclosureData.operating && disclosureData.operating.totalOlPayment ? disclosureData.operating.totalOlPayment.replace(/,/g, '') : 0), Number(disclosureData && disclosureData.financing && disclosureData.financing.totalFlPayment ? disclosureData.financing.totalFlPayment.replace(/,/g, '') : 0)]);
            worksheet.addRow(["","",""])
            worksheet.addRow(["Less Imputed Interest", Number( mode != "lessor" && disclosureData && disclosureData.operating && disclosureData.operating.imputedInt ? disclosureData.operating.imputedInt.replace(/,/g, '') : 0), Number(disclosureData && disclosureData.financing && disclosureData.financing.imputedInt ? disclosureData.financing.imputedInt.replace(/,/g, '') : 0)]);
            worksheet.addRow(["Total", Number( mode != "lessor" && disclosureData && disclosureData.operating && disclosureData.operating.olLeaseLiabilityEnd ? disclosureData.operating.olLeaseLiabilityEnd.replace(/,/g, '') : 0), Number(disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiabilityEnd ? disclosureData.financing.flLeaseLiabilityEnd.replace(/,/g, '') : 0)]);
            worksheet.getCell("A48").font = {size: 10, bold: true};
            worksheet.getCell("B48").font = {size: 10, bold: true};
            worksheet.getCell("C48").font = {size: 10, bold: true};

            // const customCell17 = worksheet.getCell("A54");
            // customCell17.font = {
            // size: 12,
            // bold: true,
            // italic: true
            // };
            // customCell17.value = `Short Term Leases`;

            // worksheet.mergeCells("A55:B55");
            // worksheet.getCell("A55").value = `Balance of the ST asset-liability`
            // worksheet.getCell("C55").value = disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.shortTermLiabilityOnEnd && disclosureData.shortTerm.shortTermLiabilityOnEnd != 'NA' ? Number(disclosureData.shortTerm.shortTermLiabilityOnEnd.replace(/,/g, '')) : 'NA'

            // worksheet.mergeCells("A56:B56");
            // worksheet.getCell("A56").value = `Future ST Lease payments`
            // worksheet.getCell("C56").value = disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.allPaymentAfterEnd && disclosureData.shortTerm.allPaymentAfterEnd != 'NA' ? Number(disclosureData.shortTerm.allPaymentAfterEnd.replace(/,/g, '')) : 'NA'
            
    
            worksheet.mergeCells("A50:C50");
            const customCell7 = worksheet.getCell("A50");
            customCell7.font = {
              size: 16,
              bold: true,
              color:{argb:'FFFFFFFF'}
            };
            customCell7.fill = {
                type: 'pattern',
                pattern:'solid',
                bgColor:{argb:'F08080'}
            };
            customCell7.value = "Cash Flow Information";
            worksheet.getRow("50").height = 30
                
            worksheet.mergeCells("A52:C52");
            const customCell8 = worksheet.getCell("A52");
            customCell8.font = {
            size: 12,
            bold: true,
            italic: true
            };
            customCell8.value = "Operating Leases";

            worksheet.mergeCells("A53:C53");
            const customCell13 = worksheet.getCell("A53");
            customCell13.font = {
                underline: true,
            };
            customCell13.value = "Cash Flow from Operating Activities:";

            worksheet.addRow(["Operating Lease - Noncash lease expense", "", disclosureData && disclosureData.operating && disclosureData.operating.nonCashExp && disclosureData.operating.nonCashExp != 'NA' ? Number(disclosureData.operating.nonCashExp.replace(/,/g, '')) : 'NA'])
            worksheet.mergeCells("A54:B54");
            worksheet.getCell('A54').alignment = { indent: 2 };

            worksheet.addRow([`Operating Lease - Change in Lease ${ mode == "lessor" ? "Receivable" : "Liability"}`, "", disclosureData && disclosureData.operating && disclosureData.operating.olLeaseLiability && disclosureData.operating.olLeaseLiability != 'NA' ? Number(disclosureData.operating.olLeaseLiability.replace(/,/g, '')) : 'NA'])
            worksheet.mergeCells("A55:B55");
            worksheet.getCell('A55').alignment = { indent: 2 };

            // worksheet.addRow([`Short Term Leases - Change in ST asset-liability`, "", disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.short_olLiability && disclosureData.shortTerm.short_olLiability != 'NA' ? Number(disclosureData.shortTerm.short_olLiability.replace(/,/g, '')) : 'NA'])
            // worksheet.mergeCells("A64:B64");
            // worksheet.getCell('A64').alignment = { indent: 2 };
    
            worksheet.mergeCells("A57:C57");
            const customCell9 = worksheet.getCell("A57");
            customCell9.font = {
              size: 12,
              bold: true,
              italic: true
            };
            customCell9.value = "Financing Leases";
    
            worksheet.mergeCells("A58:C58");
            const customCell14 = worksheet.getCell("A58");
            customCell14.font = {
                underline: true,
            };
            customCell14.value = "Cash Flow from Operating Activities:";
    
            worksheet.addRow([`Financing Lease - ${ mode == "lessor" ? "Lease revenue" : "Amortization"} of ${ mode == "lessor" ? "Deferred inflow" : "ROU Asset"}`, "", disclosureData && disclosureData.financing && disclosureData.financing.amortExp && disclosureData.financing.amortExp != 'NA' ? Number(disclosureData.financing.amortExp.replace(/,/g, '')) : 'NA'])
            worksheet.mergeCells("A59:B59");
            worksheet.getCell('A59').alignment = { indent: 2 };
    
            worksheet.mergeCells("A61:C61");
            const customCell15 = worksheet.getCell("A61");
            customCell15.font = {
                underline: true,
            };
            customCell15.value = "Cash Flow from Financing Activities:";
    
            worksheet.addRow(["Principal Payment of Financing Leases", "", disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiability && disclosureData.financing.flLeaseLiability != 'NA' ? Number(disclosureData.financing.flLeaseLiability.replace(/,/g, '')) : 0])
            worksheet.mergeCells("A62:B62");
            worksheet.getCell('A62').alignment = { indent: 2 };

            worksheet.mergeCells("A64:C64");
            const customCell10 = worksheet.getCell("A64");
            customCell10.font = {
              size: 12,
              bold: true,
              italic: true
            };
            customCell10.value = "Other Cash Flow Information";

            worksheet.addRow(["Interest Expense Payments on Financing Leases", "", disclosureData && disclosureData.financing && disclosureData.financing.interestExpPayment && disclosureData.financing.interestExpPayment != 'NA' ? Number(disclosureData.financing.interestExpPayment.replace(/,/g, '')) : 0])
            worksheet.mergeCells("A65:B65");
               
            worksheet.addRow([`Noncash Disclosure - ${ mode == "lessor" ? "Deferred inflow" : "ROU Assets"} obtained in exchange for new operating lease ${ mode == "lessor" ? "Receivables" : "Liabilities"}`, "", disclosureData && disclosureData.operating && disclosureData.operating.initRecognition && disclosureData.operating.initRecognition != 'NA' ? Number(disclosureData.operating.initRecognition.replace(/,/g, '')) : 'NA'])
            worksheet.mergeCells("A66:B66");
    
            worksheet.addRow([`Noncash Disclosure - ${ mode == "lessor" ? "Deferred inflow" : "ROU Assets"} obtained in exchange for new financing lease ${ mode == "lessor" ? "Receivables" : "Liabilities"}`, "", disclosureData && disclosureData.financing && disclosureData.financing.initRecognition && disclosureData.financing.initRecognition != 'NA' ? Number(disclosureData.financing.initRecognition.replace(/,/g, '')) : 'NA'])
            worksheet.mergeCells("A67:B67");    
        }        
        ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
            var csv_file, download_link;
            csv_file = new Blob([buffer], { type: "application/octet-stream" });
            download_link = document.createElement("a");
            download_link.download = "disclosures.xlsx";
            download_link.href = window.URL.createObjectURL(csv_file);
            download_link.style.display = "none";
            document.body.appendChild(download_link);
            download_link.click();
        });
    }

    return (
        <Layout>
        { filter ?
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Disclosures</title>
                </Helmet>
                <Container fluid>
                <Breadcrumbs title="disclosure_filter"
                    name="DISCLOSURES"
                    type="disclosure_filter"
                />
                </Container>
                <Filter handleClick={flip} type="disclosures" clientsData={mode == 'lessor' ? lessorClients : lesseeClients } loading={loading} getSearchResult={getSearchResult} dateType={dateType} setDateType={setDateType} handleChangeType={handleChangeType} accountType={accountType} setAccountType={setAccountType}/> 
            </div>
        :
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Disclosures</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumbs title="disclosure"
                        type="disclosures"
                        name1="DISCLOSURES"
                    />
                    {searchResult && Object.keys(searchResult).length > 0 &&
                        <Row>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xs="4">
                                                <h6>Date range:</h6>
                                            </Col>
                                            <Col xs="8" style={{textAlign: 'right'}}>
                                                {moment(searchResult.startDate).utc().format('MM/DD/YYYY')}-{moment(searchResult.endDate).utc().format('MM/DD/YYYY')}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                <h6>Year End:</h6>
                                            </Col>
                                            <Col xs="8" style={{textAlign: 'right'}}>
                                                {searchResult.year_end_date}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xs="4">
                                                <h6>Clients:</h6>
                                            </Col>
                                            <Col xs="8" style={{textAlign: 'right'}}>
                                                {searchResult.clients && searchResult.clients.length > 1 ? searchResult.clients[0].client+", "+searchResult.clients[1].client+(searchResult.clients.length > 2 ? " + "+(searchResult.clients.length - 2)+" others" : '') : searchResult.clients && searchResult.clients.length > 0 ? searchResult.clients[0].client : ''}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                <h6>Leases:</h6>
                                            </Col>
                                            <Col xs="8" style={{textAlign: 'right'}}>
                                                {searchResult.lease_ids && searchResult.lease_ids.length > 1 ? searchResult.lease_ids[0].name+", "+searchResult.lease_ids[1].name+(searchResult.lease_ids.length > 2 ? " + "+(searchResult.lease_ids.length - 2)+" others" : '') : searchResult.lease_ids && searchResult.lease_ids.length > 0 ? searchResult.lease_ids[0].name : ''}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div className="mb-1" style={{float:'right'}}>
                                                <button type="button" className="btn btn-lbusers" onClick={downloadCSV} disabled={loadingDisclosures || !searchResult || (searchResult && Object.keys(searchResult).length < 1) || (searchResult && searchResult.is_processing)}>Export CSV</button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        loadingDisclosures ?
                                            <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                                        :!loadingDisclosures && searchResult && searchResult.is_processing ? <div className="text-center"><h6>Your data is being processed</h6><Spinner color="primary" className="text-lbusers" /></div> : 
                                        <>
                                            <Row className="mt-3">
                                                <Col>
                                                    <Alert color="warning" className="text-black alert-text" role="alert">
                                                        Income Statement
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            {  mode != "lessor" &&
                                            <>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="12"><i className="font-italic-bold">Operating Lease { mode == "lessor" ? "Revenue" : "Costs"}:</i></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Operating Lease { mode == "lessor" ? "Revenue" : "Costs"}</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.opLeaseCosts}</h6></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>{ mode == "lessor" ? "Variable Lease Revenue" : "Variable Lease Costs"} - Operating</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.operating && disclosureData.operating.variableCost}</h6></Col>
                                                </Row>
                                            </>
                                            }
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"><i className="font-italic-bold">{ mode == "lessor" ? '' : 'Financing' } Lease { mode == "lessor" ? "Revenue" : "Costs"}:</i></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>{ mode == "lessor" ? "Lease revenue" : "Amortization"}</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.amortExp}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>{ mode == "lessor" ? "Interest Revenue" : "Interest on lease liabilities"}</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.intExp}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>{ mode == "lessor" ? "Variable Lease Revenue" : "Variable Lease Costs"} - { mode == "lessor" ? '' : 'Financing' } Leases</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.variableCost}</h6></Col>
                                            </Row>
                                            {/* <Row className="ms-1 mt-3">
                                                <Col xs="12"><i className="font-italic-bold">Short Term Lease Costs:</i></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Short term lease costs</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.shortLeaseCosts}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Variable lease costs</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.shortVariableCost}</h6></Col>
                                            </Row> */}
                                            <Row className="mt-3">
                                                <Col>
                                                    <Alert color="warning" className="text-black alert-text" role="alert" style={{fontWeight:'bold'}}>
                                                        Balance Sheet (as of Date)
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            { mode != "lessor" &&
                                            <>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="12"><i className="font-italic-bold">Operating Leases</i></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>{ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} - Operating</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.olRouEnding}</h6></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="12"></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Current Operating Lease { mode == "lessor" ? "Receivable" : "Liability"}</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.totalPV}</h6></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Noncurrent Operating Lease { mode == "lessor" ? "Receivable" : "Liability"}</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.nonCurrentLiability}</h6></Col>
                                                </Row><hr/>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Total Operating Lease { mode == "lessor" ? "Receivable" : "Liability"}</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.olLeaseLiabilityEnd}</h6></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="12"></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Weighted Average Remaining Lease Term</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.avgTermOp}</h6></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Weighted Average Discount Rate</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.avgRateOp}</h6></Col>
                                                </Row>
                                            </>
                                            }                                            
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"><i className="font-italic-bold">{ mode == "lessor" ? '' : 'Financing' } Leases</i></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>{ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} { mode == "lessor" ? '' : '- Financing' }</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.rouAtCommensement}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>{ mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} - Accumulated { mode == "lessor" ? "Lease revenue" : "Amortization"}</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.accAmort ? `(${disclosureData.financing.accAmort})` : "NA"}</h6></Col>
                                            </Row><hr/>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Total { mode == "lessor" ? "Deferred inflow" : "Right-of-Use Asset"} { mode == "lessor" ? '' : '- Financing' }</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.flRouEnding}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Current { mode == "lessor" ? '' : 'Financing' } Lease { mode == "lessor" ? "Receivable" : "Liability"}</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.totalPV}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Noncurrent { mode == "lessor" ? '' : 'Financing' } Lease { mode == "lessor" ? "Receivable" : "Liability"}</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.nonCurrentLiability}</h6></Col>
                                            </Row><hr/>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Total { mode == "lessor" ? '' : 'Financing' } Lease { mode == "lessor" ? "Receivable" : "Liability"}</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiabilityEnd}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Weighted Average Remaining Lease Term</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.avgTermF}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Weighted Average Discount Rate</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.avgRateF}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"><i className="font-italic-bold">Maturity of Lease { mode == "lessor" ? "Receivables" : "Liabilities"}:</i></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="6"><h6 className="text-black" style={{fontWeight:'bold', fontSize:'13px'}}>Year Ending [As of Date]</h6></Col>
                                                <Col xs="3">{ mode != "lessor" ? <h6 style={{fontWeight:'bold', fontSize:'13px'}} className="text-black disclosure-price-float-right">Operating Leases</h6> : ''}</Col>
                                                <Col xs="3"><h6 style={{fontWeight:'bold', fontSize:'13px'}} className="text-black disclosure-price-float-right">{ mode == "lessor" ? '' : 'Financing' } Leases</h6></Col>
                                            </Row>
                                            {
                                                disclosureData && disclosureData.olMaturity && Object.keys(disclosureData.olMaturity).length > 0 && Object.keys(disclosureData.olMaturity).map((year, key) => {
                                                    if(year !== 'thereafter'){
                                                        return <Row className="ms-1 mt-3" key={key}>
                                                            <Col xs="6"><h6>{`Year ${key + 1} (${year}) `}</h6></Col>
                                                            <Col xs="3">{ mode != "lessor" ? <h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData.olMaturity[year] || 0}</h6> : ''}</Col>
                                                            <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData.flMaturity[year] || 0}</h6></Col>
                                                        </Row>
                                                    }
                                                })
                                            }
                                            <Row className="ms-1 mt-3">
                                                <Col xs="6"><h6>Thereafter</h6></Col>
                                                <Col xs="3">{ mode != "lessor" ? <h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.olMaturity && disclosureData.olMaturity.thereafter || 0}</h6> : ''}</Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.flMaturity && disclosureData.flMaturity.thereafter || 0}</h6></Col>
                                            </Row><hr/>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="6"><h6>Total Lease Payments</h6></Col>
                                                <Col xs="3">{ mode != "lessor" ? <h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.totalOlPayment || 0}</h6> : ''}</Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.totalFlPayment || 0}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="6"><h6>Less Imputed Interest</h6></Col>
                                                <Col xs="3">{ mode != "lessor" ? <h6 className="disclosure-price-float-right">({mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.imputedInt})</h6> : ''}</Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">({disclosureData && disclosureData.financing && disclosureData.financing.imputedInt})</h6></Col>
                                            </Row><hr/>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="6"><h6 className="text-black" style={{fontWeight:'bold'}}>Total</h6></Col>
                                                <Col xs="3">{ mode != "lessor" ? <h6 className="disclosure-price-float-right text-black" style={{fontWeight:'bold'}}>{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.olLeaseLiabilityEnd}</h6> : ''}</Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right text-black" style={{fontWeight:'bold'}}>{disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiabilityEnd}</h6></Col>
                                            </Row>
                                            {/* <Row className="ms-1 mt-3">
                                                <Col xs="12"><i className="font-italic-bold">Short Term Leases</i></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Balance of the ST asset-liability</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.shortTermLiabilityOnEnd}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Future ST Lease payments</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.allPaymentAfterEnd}</h6></Col>
                                            </Row> */}
                                            <Row className="mt-3">
                                                <Col>
                                                    <Alert color="warning" className="text-black alert-text" role="alert" style={{fontWeight:'bold'}}>
                                                        Cash Flow Information
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            {  mode != "lessor" &&
                                            <>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><i className="font-italic-bold">Operating Leases</i></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="12"><h6><u>Cash Flow from Operating Activities:</u></h6></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6><pre style={{display:'inline'}}>    </pre>Operating Lease - Noncash lease expense</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.nonCashExp}</h6></Col>
                                                </Row>
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6><pre style={{display:'inline'}}>    </pre>Operating Lease - Change in Lease { mode == "lessor" ? "Receivable" : "Liability"}</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{mode == "lessor" ? "NA" : disclosureData && disclosureData.operating && disclosureData.operating.olLeaseLiability}</h6></Col>
                                                </Row>
                                                {/* <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6><pre style={{display:'inline'}}>    </pre>Short Term Leases - Change in ST asset-liability</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.shortTerm && disclosureData.shortTerm.short_olLiability}</h6></Col>
                                                </Row>    */}
                                            </>
                                            }
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><i className="font-italic-bold">{ mode == "lessor" ? '' : 'Financing' } Leases</i></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"><h6><u>Cash Flow from Operating Activities:</u></h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                {
                                                    mode != "lessor" ? <Col xs="9"><h6><pre style={{display:'inline'}}>    </pre>Financing Lease - Amortization of ROU Asset</h6></Col>
                                                    : <Col xs="9"><h6><pre style={{display:'inline'}}>    </pre>Non-cash Lease revenue from Deferred inflow</h6></Col>
                                                } 
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.amortExp}</h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="12"><h6><u>Cash Flow from { mode == "lessor" ? '' : 'Financing' } Activities:</u></h6></Col>
                                            </Row>
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6><pre style={{display:'inline'}}>    </pre>Principal { mode == "lessor" ? 'Receipts' : 'Payment' } of { mode == "lessor" ? '' : 'Financing' } Leases</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.flLeaseLiability}</h6></Col>
                                            </Row>                                                                                          
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><i className="font-italic-bold">Other Cash Flow Information</i></Col>
                                            </Row>
                                            {  mode != "lessor" &&
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Interest Expense Payments on Financing Leases</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.interestExpPayment}</h6></Col>
                                                </Row>
                                            } 
                                            {  mode != "lessor" &&
                                                <Row className="ms-1 mt-3">
                                                    <Col xs="9"><h6>Noncash Disclosure - { mode == "lessor" ? "Deferred inflow" : "ROU Assets"} obtained in exchange for new operating lease { mode == "lessor" ? "Receivables" : "Liabilities"}</h6></Col>
                                                    <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.operating && disclosureData.operating.initRecognition}</h6></Col>
                                                </Row>
                                            }
                                            <Row className="ms-1 mt-3">
                                                <Col xs="9"><h6>Noncash Disclosure - { mode == "lessor" ? "Deferred inflow" : "ROU Assets"}  obtained in exchange for new { mode == "lessor" ? '' : 'Financing' } lease { mode == "lessor" ? "Receivables" : "Liabilities"}</h6></Col>
                                                <Col xs="3"><h6 className="disclosure-price-float-right">{disclosureData && disclosureData.financing && disclosureData.financing.initRecognition}</h6></Col>
                                            </Row>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </Layout>
    )
}

export default Disclosure