import React, { useEffect, useState, useContext } from "react"
import {Helmet} from "react-helmet";
import { useParams, useNavigate } from "react-router-dom"
import {
    Card, CardBody, Col, Container, Row, UncontrolledAlert, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, TabPane, NavLink, NavItem, TabContent, Nav, Label, Form, Modal, Spinner} from "reactstrap"
import NewJournalEntries from "./newJournalEntries";
import GlMappingJE from "./glMappingJE"; 
import Payment from "./payment";
import Liability from "./liability";
import STBalance from "./stbalance"
import RouAsset from "./rouAsset";
import Summary from "./summary";
import LeaseSummary from "./leaseSummary";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumbclientdetails"
import DeleteLease from "pages/Leases/deleteLease";
import TerminateLease from "pages/Leases/terminateLease";
import { get } from 'helpers/axios_helper';
import AppConfig from 'constants/config';
import toast from 'react-hot-toast';
import moment from 'moment';
import AddClientToLease from "pages/Leases/addClientToLease";
import axios from 'axios';
import ModifyDate from "pages/Leases/modifyDate";
import RevertLease from "pages/Leases/revertLease";
import Impairment from "pages/Leases/impairment";
import UsefulLife from "pages/Leases/usefulLife";
import ShortTermLease from "pages/Leases/shortTermLease";
import { AuthContext } from "context/authContext";
import UploadFiles from "pages/Leases/uploadFiles";
import AuditCategoryDropdown from "Modules/AuditCategory/auditCategoryDropdown";
import LeaseLogs from "Modules/AuditCategory/leaseLogs";
import DownloadDropdown from "pages/Filter/downloadDropdown";
import AddVariablePayment from '../../pages/Leases/addVariablePayment'
import Layout from "components/VerticalLayout";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const LeaseDetails = () => {
    const params = useParams();
    const navigate = useNavigate()
    const { userData, mode } = useContext(AuthContext);
    const [drpDownOpen, setDrpDownOpen] = useState(false);
    const [impairment, setImpairment] = useState(false);
    const [usefulLife, setUsefulLife] = useState(false);
    const [deleteLease, setDeleteLease] = useState(false)
    const [terminateLease, setTerminateLease] = useState(false)
    const [leaseDetails, setLeaseDetails] = useState('');
    const [ jeDates, setJeDates ] = useState({});
    const [paymentsData, setPaymentsData] = useState([]);
    const [refundAmount, setRefundAmount] = useState('');
    const [leaseDraft, setLeaseDraft] = useState('');
    const [addClientLeasePopup, setAddClientLeasePopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingLeaseFlag, setLoadingLeaseFlag] = useState(false)
    const [modifyOpen, setModifyOpen] = useState(false);
    const [revertLease, setRevertLease] = useState(false);
    const [revertFromModification, setRevertFromModification] = useState(false);
    const [clientAccess, setClientAccess] = useState({});
    const [fileDropdown, setFileDropdown] = useState(false);
    const [viewOpen, setViewOpen] = useState(false)
    const [addFile, setAddFile] = useState(false)
    const [originalFiles, setOriginalFiles] = useState([])
    const [dummyFiles, setDummyFiles] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [currency, setCurrency] = useState("$");
    const [detailsMode, setDetailsMode] = useState("");
    const [reviewAccess, setReviewAccess] = useState({});
    const [variablePaymentOpen, setVariablePaymentOpen] = useState(false)
    const [variablePaymentArr, setVariablePaymentArr] = useState([])
    const [termiDate, setTermiDate] = useState('');
    const [clientData, setClientData] = useState({});
    const [unprocessImpair, setUnprocessImpair] = useState([])
    const [loadingImpairment, setLoadingImpairment] = useState(false)
    const [loadingTerminate, setLoadingTerminate] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingRevert, setLoadingRevert] = useState(false)
    const [shortTerm, setShortTerm] = useState(false);
    const [shortTermOpen, setShortTermOpen] = useState(false);
    const [shortTermModify, setShortTermModify] = useState(false);
    const [rouArrData, setRouArrData] = useState({});
    const [modData, setModData] = useState("");
    const [impairments, setImpairments] = useState([])
    const [useful, setUseful] = useState([])
    const [value, setValue] = useState('1');

    const toggleVariablePayment = () => {
        setVariablePaymentOpen(!variablePaymentOpen)
    }
    const AddClientLeaseToggle = () => {
        setAddClientLeasePopup(!addClientLeasePopup)
    }
    const toggleDeleteLease = () => {
        setDeleteLease(!deleteLease)
    }
    const toggleAddFileModel = () => {
        setAddFile(!addFile)
    }
    const toggleViewFileModel = () => {
        setViewOpen(!viewOpen)
    }

    const toggleTerminateLease = () => {
        setTerminateLease(!terminateLease)
    }

    const toggleModifyLease = () => {
        setModifyOpen(!modifyOpen)
    }

    const toggleRevertLease = () => {
        setRevertLease(!revertLease)
    }

    const modifyL = (data) => {
        let a = new Date(data.date)
        window.location.href =`/${localStorage.getItem('company')}/leases/modify?lease_id=${params.id}&client_id=${leaseDetails.client_id._id}&m_date=${data.date}&mod_type=${data.mod_type}&percentage=${data.percentage}`
    }

    const hitApi = async (d, t) => {
        setLoading(true)
        let leaseId = d ? d : params.id;
        let type = t ? t : params.type;
        let value = await get(`${AppConfig.baseUrl}/lease/lease_details?Id=${leaseId}&type=${type}&mode=${mode ? mode : 'lessee'}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        let { data } = await get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${leaseId}&mode=${mode ? mode : 'lessee'}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (data && data.lease) {
            setLeaseDraft(data.lease)
            setUnprocessImpair(data.lease && data.lease.unprocessedImpairments ? data.lease.unprocessedImpairments : [])
        }
        if (value.error === false) {
            setLeaseDetails(value.data)
            setShortTerm(value.data.is_short_term)
            setJeDates(value.journal_dates)
            setImpairments(value.impairArr)            
            setUseful(value.usefulArr)
            let client_id = value.data && value.data.client_id ? value.data.client_id._id : ''
            const { data } = await axios.get(`${AppConfig.baseUrl}/client/details?id=${client_id}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
              });
              if (!data.error) {
                setClientData(data.clientDetails)
              }
            setCurrency(value.data && value.data.currency ? value.data.currency.symbol : '$')
            setDetailsMode(value.data && value.data.mode ? value.data.mode : 'lessee')
            setValue('1')
            setDummyFiles(value.data.uploaded_files || [])
            setOriginalFiles(value.data.uploaded_files || [])
            let payment = value.data && value.data?.paymentData && value.data?.paymentData?.payments || [];
            let filter = []
            filter = payment.filter(e => moment(moment(e.due_date).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSameOrAfter(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')))
            let pvcData = value.data && value.data?.pvcData && value.data?.pvcData;
            setPaymentsData(pvcData)
            let total = 0
            if (value.data && value.data.paymentData && value.data.paymentData.payments && value.data.paymentData.payments.length > 0) {
                total = value.data.paymentData.payments.reduce((total, val) => {
                    if (val && val.type && (val.type.includes("Rent Deposit") || val.type.includes("Refundable Deposit"))) {
                        return total + Number(val.refund_amount)
                    } else {
                        return total
                    }
                }, 0)
            }
            setRefundAmount(total)
        }else {
            toast.error(value.title);
            navigate(`/${localStorage.getItem('company')}/leases`);
        }
        setLoading(false)
    }
    useEffect(() => {
        setLoading(true)
        if (params.type === 'refresh') {
            setTimeout(() => hitApi(), 4000)
        }
        else {
            hitApi()
        }
    }, [mode])
    useEffect(() => {
        if (mode && detailsMode && mode != detailsMode) {
            window.location.href = `/${localStorage.getItem('company')}/leases`
        }
    }, [mode, detailsMode])

    const toggleModel = () => {
        setImpairment(!impairment)
    }

    const changeLeaseFlag = async (flag) => {
        setLoadingLeaseFlag(true)
        try {
            let value = await get(`${AppConfig.baseUrl}/lease/pin_lease?Id=${params.id}&flag=${flag}&mode=${mode ? mode : 'lessee'}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (!value.error) {
                toast.success(value.title);
                hitApi()
            }
            else {
                throw new Error(value.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingLeaseFlag(false)
    }

    const updateImpair = async () => {
        setLoadingImpairment(true)
        try {
            let value = await get(`${AppConfig.baseUrl}/lease_draft/update_impairment?id=${params.id}&mode=${mode ? mode : 'lessee'}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (!value.error) {
                setUnprocessImpair([])
                hitApi()
            }
            else {
                throw new Error(value.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingImpairment(false)
    }
    const deleteL = async (d) => {
        setLoadingDelete(true)
        try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/lease/delete_lease`, { Id: leaseDetails._id, mode: mode ? mode : 'lessee' }, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                toast.success(data.title || 'Lease deleted successfully');
                navigate(`/${localStorage.getItem('company')}/leases`)
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingDelete(false)
    }

    const revertL = async (d) => {
        setLoadingRevert(true)
        try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/lease_draft/revertLease`, { lease_id: leaseDetails._id, mode: mode ? mode : 'lessee', type:'revert' }, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                toast.success(data.title || 'Lease reverted successfully');
                if (revertFromModification) {
                    setLoading(true);
                    let index = leaseDetails.modifiedLease.findIndex(e => (e._id).toString() == (leaseDetails._id).toString());
                    setRevertFromModification(false);
                    setRevertLease(false);
                    navigate(`/${localStorage.getItem('company')}/leases/details/${leaseDetails.modifiedLease[index + 1]._id}/other`);
                    hitApi(leaseDetails.modifiedLease[index + 1]._id)
                } else {
                    navigate(`/${localStorage.getItem('company')}/leases`);
                }
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingRevert(false)
    }

    const revertModification = () => {
        setRevertLease(true);
        setRevertFromModification(true);
    }

    const terminateL = async (d) => {
        setLoadingTerminate(true)
        try {
            let newDate = moment(d.date).format('YYYY-MM-DD');
            let amt = d.check === true ? Number(d.amount) : 0;
            const { data } = await axios.post(`${AppConfig.baseUrl}/lease/terminate_lease`, { lease_id: d.lease_id, date: newDate, amount: amt, mode: mode ? mode : 'lessee' }, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                navigate(`/${localStorage.getItem('company')}/leases/details/${data.lease_id}/refresh`);
                // setLoading(true);
                toast.success(data.title || 'Lease terminated successfully');
                hitApi(data.lease_id);
                setTerminateLease(false);
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingTerminate(false)
    }

    const getUserClientAccess = async () => {
        try {
            let value = await get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (value.error) {
                toast.error(value.error.message || 'Something went wrong');
            }
            else {
                let access = {}
                let review = {}
                for (const d of value.clientData) {
                    if (d && d.client_id) {
                        access = { ...access, [d.client_id.client]: d.access_type }
                        review = { ...review, [d.client_id.client]: d.can_review || false }
                    }
                }
                setClientAccess(access)
                setReviewAccess(review)
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
    }

    useEffect(() => {
        if (userData && userData.id) {
            getUserClientAccess();
        }
        else {
            setClientAccess({})
        }
    }, [userData])

    const saveFiles = () => {
        setViewOpen(false)
        submitFiles(dummyFiles)
    }

    const submitFiles = async (files, type) => {
        try {
            const data = {
                id: leaseDetails._id,
                uploaded_files: type == 'new' ? [...files, ...originalFiles] : files,
                mode: mode ? mode : 'lessee',
            }
            const response = await axios.post(`${AppConfig.baseUrl}/lease/updateFiles`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (response.data.error) {
                toast.error(response.data.title || 'Something went wrong');
            }
            else {
                toast.success(response.data.title);
            }
            setNewFiles([])
            hitApi('', 'refresh');
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
    }

    const removeFile = async (file) => {
        const updatedFiles = await dummyFiles.filter(v => v.preview != file.preview);
        setDummyFiles(updatedFiles);
    }
    useEffect(() => {
        if (leaseDetails) {
            setTermiDate(moment(leaseDetails.commencement).utc().add(1, 'day').format('MM/DD/YYYY'));
        }  
        if(leaseDetails && leaseDetails.modifiedLease && leaseDetails.modifiedLease.length > 0)     {
            const length = leaseDetails.modifiedLease.length
            if((moment(leaseDetails.terminal_end_date).diff(moment(leaseDetails.modifiedLease[length - 1].terminal_end_date),'months') < 12) && leaseDetails.modifiedLease[length - 1].is_short_term){
                setShortTermModify(true)
            }else{
                setShortTermModify(false)
            }
        } else{
            if(moment(leaseDetails.terminal_end_date).diff(moment(leaseDetails.commencement),'months') < 12){
                setShortTermModify(true)
            }else{
                setShortTermModify(false)
            }
        }
        let modData = leaseDetails && leaseDetails.modifiedLease && leaseDetails.modifiedLease.length > 0 ? leaseDetails.modifiedLease.find(v=>v.is_short_term == true) : ''
        setModData(modData)       
    },[leaseDetails])

    const toggleUseful = () => {
        setUsefulLife(!usefulLife)
    }

    useEffect(() => {
        if(params.id){
            getRouArrData()
        }
    },[params])

    const getRouArrData = async()=>{
        let value = await get(`${AppConfig.baseUrl}/rou_asset/get_rou_asset?Id=${params.id}&mode=${mode || 'lessee'}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if(!value.error){
            setRouArrData(value.data)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Lease Details</title>
                </Helmet>
                <Container fluid>
                    {
                        loading ?
                            <div className="text-center" style={{ marginTop: '35vh' }}><Spinner color="primary" className="text-lbusers" /></div>
                            :
                            leaseDetails && !leaseDetails.is_processing ?
                                <>
                                    <div className="d-flex justify-content-between">
                                        <Breadcrumbs title="leaseDeatails"
                                            name={(leaseDetails && leaseDetails?.name).toUpperCase()}
                                            type="leaseDeatails"
                                            leaseType={leaseDetails && leaseDetails.type}
                                            breadcrumbItem={leaseDetails && leaseDetails?.classification == "financing" ? (leaseDetails && leaseDetails.reporting_standard ? leaseDetails.reporting_standard : "FASB ASC 842") + " FINANCING LEASE" : leaseDetails && leaseDetails?.classification == "operating" ? (leaseDetails && leaseDetails.reporting_standard ? leaseDetails.reporting_standard : "FASB ASC 842") + " OPERATING LEASE" :
                                                leaseDetails && leaseDetails?.classification == "determine" ? 'LeaseJava to determine' : ''}
                                            detail="This is a super car that drifts, speeds, files and fights crime"
                                        />
                                        <div className="mt-4" style={{ float: 'right' }}>
                                            <LeaseLogs leaseDetails={leaseDetails} mode={mode || 'lessee'} />
                                            <ButtonDropdown
                                                isOpen={fileDropdown}
                                                disabled={loading}
                                                toggle={() => setFileDropdown(!fileDropdown)}
                                                className="me-2"
                                            >
                                                <DropdownToggle
                                                    caret
                                                    className="btn btn-lbusers btn-md"
                                                >
                                                    FILES <i className="mdi mdi-chevron-down" />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <DropdownItem className="text-success text-black" onClick={() => setViewOpen(true)}> View Files</DropdownItem>
                                                    <DropdownItem className="text-danger text-black" onClick={() => setAddFile(true)}> Upload Files</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                            <DownloadDropdown lease_id={leaseDetails._id} mode={mode} from="details" />
                                            {
                                                leaseDetails && leaseDetails.client_id && clientAccess && clientAccess[leaseDetails.client_id.client] === 'full_access' && (!leaseDetails.child_id || (leaseDetails.child_id && leaseDetails.child_id.length < 1)) &&
                                                <ButtonDropdown
                                                    isOpen={drpDownOpen}
                                                    toggle={() => setDrpDownOpen(!drpDownOpen)}
                                                >
                                                    <DropdownToggle
                                                        caret
                                                        className="btn btn-lbusers btn-md"
                                                    >
                                                        ACTIONS <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        {!leaseDetails.is_terminated && <DropdownItem className="text-primary text-black" onClick={toggleVariablePayment}> Variable Payments</DropdownItem>}
                                                        {(!leaseDetails.is_terminated) && <DropdownItem className="text-success text-black" href={leaseDetails.is_modified ? `/${localStorage.getItem('company')}/leases/edit_modify_lease?lease_id=${leaseDetails._id}&client_id=${leaseDetails.client_id._id}` : `/${localStorage.getItem('company')}/leases/edit?lease_id=${leaseDetails._id}&client_id=${leaseDetails.client_id._id}`}> Edit</DropdownItem>}
                                                        {!leaseDetails.is_terminated && mode != 'lessor' && <DropdownItem className="text-success text-black" onClick={() => setImpairment(true)}> Impairment</DropdownItem>}
                                                        {(leaseDetails.is_modified || leaseDetails.is_terminated) && <DropdownItem onClick={() => { setRevertLease(true) }}> Revert</DropdownItem>}
                                                        {!leaseDetails.is_terminated && <DropdownItem className="text-primary text-black" onClick={() => { setModifyOpen(true) }}> Modify (GAAP)</DropdownItem>}
                                                        {(!leaseDetails.is_modified && !leaseDetails.is_terminated) && <DropdownItem className="text-warning text-black" onClick={() => setAddClientLeasePopup(true)}> Clone</DropdownItem>}
                                                        {!leaseDetails.is_terminated && <DropdownItem className="text-danger text-black" onClick={() => { setTerminateLease(true) }}> Terminate</DropdownItem>}
                                                        <DropdownItem className="text-danger text-black" onClick={() => { setDeleteLease(true) }}> Delete</DropdownItem>
                                                        {/* {mode != 'lessor' && !leaseDetails.is_terminated && !leaseDetails.is_short_term && shortTermModify && leaseDetails.classification == 'operating' && <DropdownItem onClick={() => { setShortTermOpen(true);}}>Apply ST Exemption</DropdownItem>}
                                                        {mode != 'lessor' && !leaseDetails.is_terminated && leaseDetails.is_short_term && <DropdownItem onClick={() => { setShortTermOpen(true);}}>Un-apply ST Exemption</DropdownItem>} */}
                                                        {!leaseDetails.is_terminated && mode != 'lessor' && <DropdownItem className="text-success text-black" onClick={() => setUsefulLife(true)}> Change Useful Life</DropdownItem>}
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            }
                                            <div className="d-flex justify-content-end">
                                                <div className="mt-3">
                                                    <Label for="confirm" className="col-form-label">
                                                        Pin Lease
                                                    </Label>
                                                </div>
                                                <div className="form-check form-switch mt-4 ms-2 me-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input switch-icon-color"
                                                        id="pinLease"
                                                        checked={leaseDetails.flagged_lease}
                                                        name="pinLease"
                                                        onChange={(e) => {
                                                            changeLeaseFlag(e.target.checked)
                                                        }}
                                                        disabled={loadingLeaseFlag}
                                                    />
                                                    {
                                                        loadingLeaseFlag && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                    }
                                                    <label
                                                        className="form-check-label "
                                                        htmlFor="has_transitioned"
                                                    />
                                                </div>
                                                <AuditCategoryDropdown reviewAccess={reviewAccess} leaseDetails={leaseDetails} clientAccess={clientAccess} type="details" refreshData={hitApi} mode={mode} />
                                            </div>
                                        </div>
                                    </div>
                                    {   unprocessImpair && unprocessImpair.length > 0 && mode != 'lessor' &&
                                    <Row>
                                        <Col xs='12'>
                                            <UncontrolledAlert
                                            color="danger"
                                            className="alert-dismissible fade show"
                                            role="alert"
                                            toggle={updateImpair}
                                            >
                                            <p style={{marginBottom:'0px'}}>The following {unprocessImpair && unprocessImpair.length > 1 ? 'impairments were' : 'impairment was'} not processed because the ROU charges exceeded the ROU asset balance</p>
                                            {
                                                unprocessImpair.map((e,index) => 
                                                    <p key={index} style={{marginBottom:'0px'}}> {"- Impairment of " + (e.amount ? Number(e.amount).toFixed(2) : 0) + " on " +  moment(e.date).format('MM/DD/YYYY')}</p>)
                                            }
                                            </UncontrolledAlert>
                                        </Col>
                                    </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <Box>
                                                        <ThemeProvider theme={createTheme({components: {
                                                        MuiTab:{
                                                            styleOverrides:{
                                                                root: {
                                                                    fontFamily:'"Poppins",sans-serif',
                                                                    fontSize: '0.8125rem',
                                                                    fontWeight: 500,
                                                                    color: '#495057',
                                                                    '&:hover':{
                                                                    color: '#005691'
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        }})}>
                                                        <TabContext value={value}>
                                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <TabList onChange={handleChange} variant="fullWidth">
                                                                <Tab label="SUMMARY" value="1" />
                                                                <Tab label="PAYMENTS" value="2" />
                                                                {!shortTerm &&
                                                                    <Tab label={detailsMode == 'lessor' ? "RECEIVABLE" : "LIABILITY"} value="3" />                                                                    
                                                                }
                                                                {!shortTerm &&
                                                                    <Tab label={detailsMode == 'lessor' ? "DEFERRED INFLOW OF RESOURCES" : "ROU ASSET"} value="4" />
                                                                }
                                                                {(shortTerm || (modData && modData.is_short_term)) &&
                                                                    <Tab label="ST BALANCE" value={!shortTerm ? "5" : "3"} />
                                                                }
                                                                <Tab label="JOURNAL ENTRIES" value={(!shortTerm && (modData && modData.is_short_term)) ? "6" : shortTerm ? "4" :"5"} />
                                                                <Tab label={ <>
                                                                    <div className="d-flex justify-content-center">
                                                                        <span className="d-none d-sm-block">G/L MAPPED JE</span> 
                                                                        <div style={{marginTop:'-10px'}}>{leaseDetails && !leaseDetails.is_glmap_processed ? <i className="fa fa-circle text-danger" style={{fontSize:'6px'}}></i> : ''}</div>                                                              
                                                                    </div>     
                                                                    <span className="d-none d-sm-block" style={{fontSize:'11px'}}>(Experimental)</span> 
                                                                    </>
                                                                } value={(!shortTerm && (modData && modData.is_short_term)) ? "7" :shortTerm ? "5" :"6"} />
                                                                {(leaseDetails.is_modified && leaseDetails.is_modified === true) && 
                                                                    <Tab label="MODIFICATIONS" value={(!shortTerm && (modData && modData.is_short_term)) ? "8" :shortTerm ? "6" :"7"} />
                                                                }
                                                            </TabList>
                                                            </Box>
                                                            <TabPanel value="1"><LeaseSummary rouArrData={rouArrData} leaseDetails={leaseDetails} clientData={clientData} currency={currency} paymentsData={paymentsData} leaseDraft={leaseDraft} refundAmount={refundAmount} impairments={impairments} useful={useful}/></TabPanel>
                                                            <TabPanel value="2"><Payment loading={loading} data={leaseDetails && leaseDetails?.paymentData && leaseDetails?.paymentData?.payments} variableData={leaseDraft && leaseDraft.oldVariablePreview ? leaseDraft.oldVariablePreview : []} currency={currency} /></TabPanel>
                                                            {!shortTerm &&
                                                            <>
                                                                <TabPanel value="3"><Liability data={leaseDetails && leaseDetails?.leaseLiability} leaseDetails={leaseDetails} type={leaseDetails?.classification} isModified={leaseDetails.is_modified} currency={currency} /></TabPanel>
                                                                <TabPanel value="4"><RouAsset data={leaseDetails && leaseDetails?.rouAsset} leaseDetails={leaseDetails} currency={currency} mode={detailsMode} isModified={leaseDetails.is_modified} /></TabPanel>
                                                            </>
                                                            }
                                                            {(shortTerm || (modData && modData.is_short_term)) && 
                                                                <TabPanel value={(!shortTerm) ? "5" : "3"}><STBalance data={leaseDetails} type={leaseDetails?.classification} isModified={leaseDetails.is_modified} currency={currency} /></TabPanel>
                                                            } 
                                                            <TabPanel value={(!shortTerm && (modData && modData.is_short_term)) ? "6" : shortTerm ? "4" :"5"}><NewJournalEntries loading={loading} shortTerm={shortTerm} leaseDetails={leaseDetails} data={leaseDetails && leaseDetails?.journalData && leaseDetails?.journalData} detailData={leaseDetails && leaseDetails?.jeDetailData && leaseDetails?.jeDetailData} type={leaseDetails?.classification} isModified={leaseDetails.is_modified} currency={currency} mode={detailsMode} />
                                                            </TabPanel>
                                                            <TabPanel value={(!shortTerm && (modData && modData.is_short_term)) ? "7" : shortTerm ? "5" :"6"}><GlMappingJE loading={loading} data={leaseDetails} jeDates={jeDates} currency={currency} mode={detailsMode} hitApi={hitApi} />
                                                            </TabPanel>
                                                            <TabPanel value={(!shortTerm && (modData && modData.is_short_term)) ? "8" : shortTerm ? "6" :"7"}><Summary data={leaseDetails && leaseDetails?.modifiedLease && leaseDetails?.modifiedLease} leaseDetails={leaseDetails} revertModification={revertModification} />
                                                            </TabPanel>
                                                        </TabContext>
                                                        </ThemeProvider>
                                                    </Box>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <div style={{ textAlign: 'center', marginTop: '30vh' }}>
                                    <h6 style={{ fontSize: '20px' }}>
                                        Your lease is currently being processed in the background and you will receive notification when it is completed.
                                        Please do not refresh this screen, but you may use the rest of LeaseJava functionality in the meantime.
                                    </h6>
                                    <div className="text-center" style={{ marginTop: '20px' }}><Spinner color="primary" className="text-lbusers" /></div>
                                </div>
                    }
                </Container>
            </div>
            <RevertLease loading={loadingRevert} deleteOpen={revertLease} toggleDelete={toggleRevertLease} setDeleteOpen={setRevertLease} lease={leaseDetails} deleteL={revertL} />
            <DeleteLease loading={loadingDelete} deleteOpen={deleteLease} toggleDelete={toggleDeleteLease} setDeleteOpen={setDeleteLease} lease={leaseDetails} deleteL={deleteL} />
            <ModifyDate modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={leaseDetails} modifyL={modifyL} termiDate={termiDate}/>
            <TerminateLease loading={loadingTerminate} terminateOpen={terminateLease} toggleTerminateLease={toggleTerminateLease} setTerminateOpen={setTerminateLease} termiDate={termiDate} setTermiDate={setTermiDate} modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={leaseDetails} terminateL={terminateL} modifyL={modifyL} />
            {addClientLeasePopup &&
                <AddClientToLease addOpen={addClientLeasePopup} toggleAdd={AddClientLeaseToggle} setOpenPopup={setAddClientLeasePopup} type='clone' lease_id={leaseDetails._id} />
            }
            <ShortTermLease shortTermOpen={shortTermOpen} setShortTermOpen={setShortTermOpen} lease={leaseDetails} refreshData={hitApi}/>
            <Impairment loading={loadingImpairment} impairment={impairment} setImpairment={setImpairment} toggleImpairment={toggleModel} leaseId={leaseDetails._id} refreshData={hitApi} />
            <UsefulLife usefulLife={usefulLife} setUsefulLife={setUsefulLife} toggleUseful={toggleUseful} leaseId={leaseDetails._id} refreshData={hitApi} />
            <UploadFiles selectedFiles={newFiles} setselectedFiles={setNewFiles} addFile={addFile} toggleModel={toggleAddFileModel} submitFiles={submitFiles} type="details" />
            <AddVariablePayment type={'leaseDetails'} hitApi={hitApi} lease_id={params.id} variablePaymentOpen={variablePaymentOpen} toggleVariablePayment={toggleVariablePayment} setVariablePaymentOpen={setVariablePaymentOpen} variablePayments={variablePaymentArr} setVariablePayments={setVariablePaymentArr} />
            <Modal
                isOpen={viewOpen}
                toggle={() => {
                    setDummyFiles(originalFiles);
                    toggleViewFileModel();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Uploaded Files</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setDummyFiles(originalFiles);
                            toggleViewFileModel();
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {dummyFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    {
                                                        f.type === 'application/pdf' ?
                                                            <i className='bx bxs-file-pdf' style={{ fontSize: '30px' }}></i>
                                                            : <i className='bx bxs-file-doc' style={{ fontSize: '30px' }}></i>
                                                    }
                                                </Col>
                                                <Col className="d-flex justify-content-between">
                                                    <div>
                                                        <a className="text-muted font-weight-bold mb-0" target='_blank' href={f.preview} rel="noreferrer">
                                                            {f.name}
                                                        </a>
                                                        <p className="mb-0">
                                                            <strong>{f.formattedSize}</strong>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <i className='bx bx-x' style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} onClick={() => removeFile(f)}></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>
                        <div className="mt-3 text-right">
                            <button type="button" disabled={leaseDetails.uploaded_files && dummyFiles.length == leaseDetails.uploaded_files.length} className="btn bg-theme-color w-md" style={{ float: 'right' }} onClick={saveFiles}>
                                SAVE
                            </button>
                        </div>

                    </Form>
                </div>
            </Modal>
        </Layout>
    )
}


export default LeaseDetails
