import React, { useState, useEffect, useContext } from "react"
import {Helmet} from "react-helmet";
import { Card,CardBody,Col, Container, NavItem,NavLink,Row,TabContent,TabPane,Form, Spinner} from "reactstrap"
import classnames from "classnames"
import { useParams, useLocation } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumbclientdetails"
import GlaccountStepOne from "./glaccount";
import AllocationStepTwo from "./allocations";
import GlgroupStepThree from "./glGroup";
import axios from "axios";
import AppConfig from "../../constants/config";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext"
import Layout from "components/VerticalLayout";

const AddAccount = () => {
    const { mode } = useContext(AuthContext)
    const [id, setId] = useState('');
    const params = useParams();
    const location = useLocation();
    const [activeTab, setactiveTab] = useState(1)
    const [clientName, setClientName] = useState('')
    const [clientData, setClientData] = useState([]);
    const isLastStep = activeTab === 3;
    const [loadingData, setLoadingData] = useState(false)

    useEffect(() => {
        setId((location.search).replace('?', ''));
      }, [])
    
      useEffect(()=>{
        if(mode && id){
          getClientDetails(id)
        }
      },[mode,id])

    const getClientDetails = async () => {
        setLoadingData(true)
        try {
          const { data } = await axios.get(`${AppConfig.baseUrl}/client/details?id=${id}&mode=${mode ? mode : 'lessee'}`, {
            headers: { token: localStorage.getItem("token") }
          });
          if (!data.error) {
            setClientName(data.clientDetails && data.clientDetails.client ? data.clientDetails.client : '')
            setClientData(data.clientDetails)
          }
          else {
            throw new Error(data.title);
          }
        } catch (error) {
          toast.error(error.message || 'Something went wrong');
        }
        setLoadingData(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeTab])

    useEffect(() => {
        if(mode && clientData && clientData.client_type && mode != clientData.client_type){
            window.location.href = `/${localStorage.getItem('company')}/clients`
        }
    },[mode])

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | GL setup</title>
                </Helmet>
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" name={clientName} breadcrumbItem={"G/L setup"}/>
                    {
                        loadingData ?
                            <div className="text-center"><Spinner color="primary" className="text-lbusers" /></div>
                            :
                            <Row>
                                <Col lg="12" sm="12">
                                    <Card>
                                        <CardBody style={{ padding: 0 }}>
                                            <div className="wizard clearfix">
                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 1 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">1</div>
                                                                    <div className="client">G/L Accounts</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 2 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">2</div>
                                                                    <div className="client">Allocation</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 3 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 3 })}
                                                            >
                                                                <div className="d-flex">
                                                                    <div className="number">3</div>
                                                                    <div className="client">G/L Templates</div>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <Form className="form-horizontal">
                                                    <div className="content clearfix mt-3">
                                                        <TabContent activeTab={activeTab}>
                                                            <TabPane tabId={1}>
                                                                <GlaccountStepOne setactiveTab={setactiveTab}/>
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <AllocationStepTwo setactiveTab={setactiveTab} activeTab={activeTab}/>
                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <GlgroupStepThree setactiveTab={setactiveTab}/>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
        </Layout>
    )
}

export default AddAccount
