import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Input, Nav, NavItem, NavLink, TabContent, TabPane, InputGroup, Modal, Spinner } from "reactstrap";
import moment from 'moment';
import { commaSeperated } from "helpers/formatters"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "../../constants/config";
import GLMapping from "./glmapping";
import { AuthContext } from "context/authContext"
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const GlMappingJE = (prop) => {
  const { reloadProcessingStatus, mode } = useContext(AuthContext);
  const [InitialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(true);
  const [glGroups, setGlGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('new');
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [title, setTitle] = useState('');
  const [template, setTemplate] = useState([])
  const [templateDates, setTemplateDates] = useState([])
  const [templateData, setTemplateData] = useState({})
  const [leaseData, setLeaseData] = useState({})
  const [buttonDisable, setButtonDisable] = useState(false);
  const [type, setType] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [search, setSearch] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [glData, setGlData] = useState(mode == 'lessor' ? 
  [{ 'jecolumn': 'Receipt Suspense Account Before Commencement', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Deposit', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Receipt Suspense Account', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "Deferred inflow of resources", "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "Receivable" , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Interest Income', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Lease Revenue' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Financing Variable Lease Revenue' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Modification Loss or Gain', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Transition', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }] : 
  [{ 'jecolumn': 'Payment Suspense Account Before Commencement' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Deposit', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Payment Suspense Account', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn':  "ROU Asset", "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': "Lease Liability" , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Rent Expense' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Interest Expense', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Amortization' , "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Operating Variable Lease Expense', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Financing Variable Lease Expense', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Modification Loss or Gain', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Impairment', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }, { 'jecolumn': 'Transition', "assigned_glaccount": '', "allocation_type": '', "allocations": '', "allocation_percentage": 100 }]
  );
  const columns = [
    {
      label: 'Date',
      name: 'date',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => ({style: {minWidth: '100px',height: '40px', position:'sticky', left: 0,backgroundColor: rowIndex % 2 === 0 ? '#fff' : '#eeeeee'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px', position:'sticky', left: 0, zIndex: 1000,height: '50px'}}),
      }
    },
    {
      label: 'Description',
      name: 'description',
      options: {
        filter: false,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => ({style: {minWidth: '250px', position:'sticky', left: 100,backgroundColor: rowIndex % 2 === 0 ? '#fff' : '#eeeeee'}}),
        setCellHeaderProps: () => ({style: {minWidth: '250px', position:'sticky', left: 100, zIndex: 1000}}),
      }
    },
    {
      label: 'Account Number',
      name: 'account_no',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '150px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
      }
    },
    {
      label: 'Account Description',
      name: 'account_description',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '250px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
      }
    },
    {
      label: 'Allocation Type',
      name: 'allocation_type',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '150px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
      }
    },
    {
      label: 'Allocation Number',
      name: 'allocation_no',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '150px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
      }
    },
    {
      label: 'Allocation Description',
      name: 'allocation_description',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '200px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
      }
    },
    {
      label: 'Financial statement line item',
      name: 'fsli',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '250px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
      }
    },
    {
      label: 'Amount',
      name: 'amount',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
      }
    },
    {
      label: 'Amount (Absolute value)',
      name: 'amount_abs',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '200px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
      }
    },
    {
      label: 'Debit/Credit',
      name: 'debit_credit',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
      }
    },
    {
      label: 'Debit',
      name: 'debit',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
      }
    },
    {
      label: 'Credit',
      name: 'credit',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
      }
    }
  ]

  const templateColumns = [
    {
      label: 'Title',
      name: 'title',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
      }
    },
    {
      label: 'Start Date',
      name: 'start_date',
      options: {
        filter: false,
        sort: true,
        customBodyRender:(data)=>(
          moment.utc(data).format('MM/DD/YYYY')
        ),
      }
    },
    {
      label: 'End Date',
      name: 'end_date',
      options: {
        filter: false,
        sort: true,
        customBodyRender:(data)=>(
          moment.utc(data).format('MM/DD/YYYY')
        ),
      }
    }, {
      name: "menu",
      label: 'Actions',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite:(dataIndex)=>(
          <div>
            {   <>
                <button
                    type="button"
                    className="btn btn-sm btn-lbusers me-2"
                    onClick={() => {
                      setType('edit'); 
                      setSelectedGroup('new'); 
                      setTemplateData(template && template[dataIndex]);
                      setModal(true);
                    }}
                    disabled={loading}
                >
                    EDIT
                </button>
                <button
                    type="button"
                    className="btn btn-sm btn-lbusers"
                    onClick={() => {setTemplateData(template && template[dataIndex]);setDeleteOpen(true)}}
                    disabled={loading}
                >
                    DELETE
                </button>
                </>
              }
          </div>
        ),
      }
    },
  ]

  useEffect(() => {
    if (prop.data) {
      let data = prop.data && prop.data.glmappedData && prop.data.glmappedData.length > 0 ? prop.data.glmappedData : []
      let mapData = data && data.filter(d => Number(Number(d.amount).toFixed(2)) != 0)
      const mainData = []
      for(const d of mapData){
        let obj = {
          account_description: d.account_description ? d.account_description : '',
          account_no: d.account_no ? d.account_no : '',
          allocation_description: d.allocation_description ? d.allocation_description : '',
          allocation_no: d.allocation_no ? d.allocation_no : '',
          allocation_type: d.allocation_type ? d.allocation_type : '',
          amount: d.amount && Number(Number(d.amount).toFixed(2)) != 0 ? commaSeperated(Number(d.amount)) : '',
          date: moment.utc(d.date).format('MM/DD/YYYY'),
          description: d.description ? d.description : '',
          fsli: d.fsli ? d.fsli : '',
          debit: Number(Number(d.amount).toFixed(2)) > 0 ? commaSeperated(Number(d.amount)) : '',
          credit:Number(Number(d.amount).toFixed(2)) < 0 ? commaSeperated(Number(Math.abs(d.amount))) : '',
          amount_abs: d.amount && Number(Number(d.amount).toFixed(2)) != 0 ? commaSeperated(Number(Math.abs(d.amount))) : '',
          debit_credit: Number(Number(d.amount).toFixed(2)) > 0 ? 'Debit' : Number(Number(d.amount).toFixed(2)) < 0 ? 'Credit' : ''
        }
        mainData.push(obj)
      }
      setInitialData(mainData || [])
      setOriginalData(mainData || [])
    }
  }, [prop.data])

  useEffect(() => {
    if (prop.jeDates && prop.jeDates[0]) {
      setStartDate(moment(prop.jeDates[0].start).utc().format('MM/DD/YYYY'))
      setEndDate(moment(prop.jeDates[0].end).utc().format('MM/DD/YYYY'))
      setLastDate(moment(prop.jeDates[0].end).utc().format('MM/DD/YYYY'))
    }
  }, [prop.jeDates])

  useEffect(() => {
    getTemplateDatas()
    if (prop.mode && prop.data && prop.data.client_id && prop.data.client_id._id) {
      getGlData(prop.data.client_id._id)
    }
  }, [prop.mode, prop.data])

  const getGlData = async (id) => {
    setLoading(true)
    setGlGroups([])
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/gl_template/list_gl_template??mode=${mode ? mode : 'lessee'}&client_id=${id}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        const arr = data.glGroupData || []
        setGlGroups(arr)
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  const getTemplateDatas = async () => {
    setLoadingTemplate(true)
    setTemplate([])
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/lease_glmapping/list_glmapping?mode=${prop.mode ? prop.mode : 'lessee'}&lease_id=${prop && prop.data && prop.data._id}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        setTemplate(data.templateData || [])
        setLeaseData(data.leaseData || {})
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoadingTemplate(false)
  }

  const addLeaseTemplate = async () => {
    setLoading(true)
    try {
        if (!startDate || !endDate) throw new Error("Please enter proper dates");
        let values = { mode: mode, client_id: prop && prop.data && prop.data.client_id && prop.data.client_id._id, lease_id: prop && prop.data && prop.data._id, glData: glData, start_date: startDate, end_date: endDate, title: title }
 
        let paymentTotal = 0, rentTotal = 0, rentPaymentTotal = 0, rouTotal = 0, liabilityTotal = 0, expenseTotal = 0, transitionTotal = 0, interestTotal = 0, amortTotal = 0, operatingTotal = 0, financingTotal = 0, modTotal = 0, total = 0,stLiabilityTotal= 0
        for (const i of glData) {
          if (new RegExp(i.jecolumn, 'ig').test('Receipt Suspense Account Before Commencement') || new RegExp(i.jecolumn, 'ig').test('Payment Suspense Account Before Commencement')) {
            paymentTotal = paymentTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test("Rent Deposit")) {
            rentTotal = rentTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test("Rent Receipt Suspense Account") || new RegExp(i.jecolumn, 'ig').test("Rent Payment Suspense Account")) {
            rentPaymentTotal = rentPaymentTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test('Deferred inflow of resources') || new RegExp(i.jecolumn, 'ig').test('ROU Asset') || new RegExp(i.jecolumn, 'ig').test('Rou Asset')) {
            rouTotal = rouTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test("Receivable") || new RegExp(i.jecolumn, 'ig').test("Lease Liability") || new RegExp(i.jecolumn, 'ig').test("Lease liability")) {
            liabilityTotal = liabilityTotal + Number(i.allocation_percentage || 0)
          } else if(new RegExp(i.jecolumn, 'ig').test("Lease Asset-Liability")){
            stLiabilityTotal = stLiabilityTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test("Rent Expense")) {
            expenseTotal = expenseTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test('Interest Income') || new RegExp(i.jecolumn, 'ig').test('Interest Expense')) {
            interestTotal = interestTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test('Lease Revenue') || new RegExp(i.jecolumn, 'ig').test('Amortization')) {
            amortTotal = amortTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test('Operating Variable Lease Revenue') || new RegExp(i.jecolumn, 'ig').test('Operating Variable Lease Expense')) {
            operatingTotal = operatingTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test('Financing Variable Lease Revenue') || new RegExp(i.jecolumn, 'ig').test('Financing Variable Lease Expense')) {
            financingTotal = financingTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test("Modification Loss or Gain")) {
            modTotal = modTotal + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test("Impairment")) {
            total = total + Number(i.allocation_percentage || 0)
          } else if (new RegExp(i.jecolumn, 'ig').test("Transition")) {
            transitionTotal = transitionTotal + Number(i.allocation_percentage || 0)
          }
        }
        if (paymentTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'} must be 100`);
        if (rentTotal != 100) throw new Error("Total of allocation percentage for Rent Deposit must be 100");
        if (rentPaymentTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"} must be 100`);
        if (rouTotal != 100) throw new Error(`Total of allocation percentage for ${ mode == 'lessor' ? 'Deferred inflow of resources' : 'ROU Asset'} must be 100`);
        if (liabilityTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? "Receivable" : "Lease Liability"} must be 100`);
        if (stLiabilityTotal != 100) throw new Error("Total of allocation percentage for Lease Asset-Liability must be 100");
        if (expenseTotal != 100 && mode != 'lessor') throw new Error("Total of allocation percentage for Rent Expense must be 100");
        if (interestTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Interest income' : 'Interest Expense'} must be 100`);
        if (amortTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Lease Revenue' : 'Amortization'} must be 100`);
        if (operatingTotal != 100 && mode != 'lessor') throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Operating Variable Lease Revenue' : 'Operating Variable Lease Expense'} must be 100`);
        if (financingTotal != 100) throw new Error(`Total of allocation percentage for ${mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'} must be 100`);
        if (modTotal != 100) throw new Error("Total of allocation percentage for Modification Loss or Gain must be 100");
        if (total != 100 && mode != 'lessor') throw new Error("Total of allocation percentage for Impairment must be 100");
        if (transitionTotal != 100) throw new Error("Total of allocation percentage for Transition must be 100");

        if(type == 'edit'){
          values.id = templateData && templateData._id
          const { data } = await axios.post(`${AppConfig.baseUrl}/lease_glmapping/edit_glmapping`, values, {
            headers: { token: localStorage.getItem("token") }
          });
          if (!data.error) {
            toast.success(data.message || 'G/L Mapping edited successfully');    
            getTemplateDatas()
            setModal(false)
            setType('')
            setTemplateData({})
            prop.hitApi()
          }
          else {
            throw new Error(data.title);
          }
        }else{
          const { data } = await axios.post(`${AppConfig.baseUrl}/lease_glmapping/add_glmapping`, values, {
            headers: { token: localStorage.getItem("token") }
          });
          if (!data.error) {
            toast.success(data.message || 'G/L Mapping added successfully');    
            getTemplateDatas()
            setModal(false)
            setType('')
            setTemplateData({})
          }
          else {
            throw new Error(data.title);
          }
        }
  
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  const processTemplate = async () => {
    setLoading(true)
    try {
        const value = await axios.post(`${AppConfig.baseUrl}/gl_template/process_leases`, { mode: mode ? mode : 'lessee', lease_id: prop.data && prop.data._id }, {
          headers: { token: localStorage.getItem("token") }
        });
        if (!value.error) {
          reloadProcessingStatus()
          window.location.reload()
        }else {
          throw new Error(value.title);
        }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  const handleChange = (event, newValue) => {
    setverticalActiveTab(newValue);
};

  useEffect(() => {
    if (template && template.length > 0) {
      let disableDates = []
      let len = template.length
      if(len == 1){
        if(!(moment(moment(prop && prop.jeDates && prop.jeDates[0] && prop.jeDates[0].start).utc().format('MM/DD/YYYY')).isSame(moment(moment(template[0].start_date).utc().format('MM/DD/YYYY')))) || !(moment(lastDate, "MM/DD/YYYY").isSame(moment(moment(template[0].end_date).utc().format('MM/DD/YYYY'))))){
          setButtonDisable(true)
        }else{
          setButtonDisable(false)
        }
      }else{        
        for(const [i,data] of template.entries()){
          if(i == 0 && !(moment(moment(prop && prop.jeDates && prop.jeDates[0] && prop.jeDates[0].start).utc().format('MM/DD/YYYY')).isSame(moment(moment(data.start_date).utc().format('MM/DD/YYYY'))))){
            setButtonDisable(true)
            break
          }else if((i == (len - 1)) && !((moment(lastDate, "MM/DD/YYYY").isSame(moment(moment(data.end_date).utc().format('MM/DD/YYYY')))) && (moment(moment(template[len - 2].end_date).add(1,'day').utc().format('MM/DD/YYYY')).isSame(moment(moment(data.start_date).utc().format('MM/DD/YYYY')))))){
            setButtonDisable(true)
            break
          }else if(i > 0 && i < (len - 1)  && !(moment(moment(moment(data.start_date).subtract(1,'day').utc().format('MM/DD/YYYY'))).isSame(moment(moment(template[i - 1].end_date)).utc().format('MM/DD/YYYY')))){
            setButtonDisable(true)
            break
          }else{
            setButtonDisable(false)
          }
        }
      }
      for(const data of template){
        disableDates.push({from: moment(data.start_date).utc().format('MM/DD/YYYY') ,to: moment(data.end_date).utc().format('MM/DD/YYYY')})
      }

      if(type == 'edit' && templateData){
        setTitle(templateData.title || '')
        setStartDate(moment(templateData && templateData.start_date).utc().format('MM/DD/YYYY'));
        setEndDate(moment(templateData && templateData.end_date).utc().format('MM/DD/YYYY'));
        disableDates = disableDates.filter(v => !(moment(moment(v.from).utc().format('MM/DD/YYYY')).isSame(moment(moment(templateData.start_date,'YYYY-MM-DD').utc().format('MM/DD/YYYY')))))
      }else{
        setTitle('')
        setSelectedGroup('new')
        const endData = template[len - 1] 
        setStartDate(moment(endData && endData.end_date).add(1,'day').utc().format('MM/DD/YYYY'))      
        setEndDate(lastDate);
      }
      setTemplateDates(disableDates)     
    }else{
      setButtonDisable(true)
      setTemplateDates([]) 
    }
  }, [template,templateData])

  const deleteLeaseTemplate = async() => {
    setLoading(true)
    try {
        const {data} = await axios.post(`${AppConfig.baseUrl}/lease_glmapping/delete_glmapping`, {id: templateData && templateData._id, mode: mode || 'lessee'},{
            headers:{token: localStorage.getItem('token')}
        });
        if(!data.error){
            toast.success("G/L Mapping deleted successfully");
            getTemplateDatas()
            setDeleteOpen(false);
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        getTemplateDatas()
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
}

const handleSearch = (value) => {
  const data = originalData.filter(v => v.date.match(new RegExp(value,"i")) || v.description.match(new RegExp(value,"i")) || v.account_no.match(new RegExp(value,"i")) || v.account_description.match(new RegExp(value,"i")) || v.allocation_type.match(new RegExp(value,"i")) || v.allocation_no.match(new RegExp(value,"i")) || v.allocation_description.match(new RegExp(value,"i")) || v.fsli.match(new RegExp(value,"i")) || v.amount.match(new RegExp(value,"i")))
  setInitialData(data)
}

  return (
    <React.Fragment>
      <Box>
        <ThemeProvider
          theme={createTheme({
            components: {
              MuiTab: {
                styleOverrides: {
                  root: {
                    fontFamily: '"Poppins",sans-serif',
                    fontSize: "0.8125rem",
                    fontWeight: 400,
                    color: "#495057",
                    letterSpacing: 0,
                    textTransform: "unset",
                    minHeight: '40px',
                    padding: 0,
                    borderRadius: '5px',
                    "&:hover": {
                      color: "#005691",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#005691",
                      color: "#fff",
                    },
                  },
                },
              },
              MuiTabPanel: {
                styleOverrides:{
                  root: {
                    padding: 0
                  }
                }
              }
            },
          })}
        >
          <TabContext value={verticalActiveTab}>
            <Row>
              <Col sm="4" md="3" lg="2">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    orientation="vertical"
                    variant="scrollable"
                    indicatorColor="#fff"
                  >
                    <Tab label="G/L Mapping" value="1" />
                    <Tab
                      label="JE data" value="2" />
                  </TabList>
                </Box>
              </Col>
              <Col sm="8" md="9" lg="10">
                <TabPanel value="1">
                  <ThemeProvider
                    theme={createTheme({
                      components: {
                        ...getMuiTheme(),
                        MUIDataTableToolbar: {
                          styleOverrides: {
                            actions: {
                              display: "contents",
                            },
                          },
                        },
                      },
                    })}
                  >
                    <MUIDataTable
                      title={
                        <Row className="row">
                          <Col md="6"></Col>
                          <Col md="6">
                            <div
                              className="d-flex mb-3"
                              style={{ float: "right", marginRight: "-10px", marginTop: "-10px" }}
                            >
                              <button
                                type="button"
                                disabled={loading || !buttonDisable}
                                className="btn bg-theme-color w-md"
                                onClick={() => {
                                  setModal(!modal);
                                }}
                              >
                                ADD G/L MAPPING
                              </button>
                              <div id="tooltipOpen">
                                <button
                                  type="button"
                                  disabled={
                                    loading ||
                                    buttonDisable ||
                                    (leaseData && leaseData.is_glmap_processed)
                                  }
                                  className="btn bg-theme-color w-md ms-3"
                                  onClick={() => {
                                    processTemplate();
                                  }}
                                >
                                  PROCESS
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      }
                      data={template}
                      columns={templateColumns}
                      key={Math.random()}
                      options={{
                        rowsPerPage: 50000,
                        print: false,
                        download: false,
                        filter: false,
                        viewColumns: false,
                        selectableRows: "none",
                        search: false,
                        rowsPerPageOptions: [],
                        responsive:'scroll',
                        textLabels: {
                          body: {
                            noMatch: loadingTemplate ? (
                              <div>
                                <Spinner
                                  color="primary"
                                  className="text-lbusers"
                                />
                              </div>
                            ) : (
                              <div>
                                Please click{" "}
                                <a
                                  href={`/${localStorage.getItem("company")}/clients/glsetup?${prop.data && prop.data.client_id && prop.data.client_id._id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  here
                                </a>{" "}
                                to setup G/L Accounts and Mapping templates.
                              </div>
                            ),
                          },
                          viewColumns: {
                            title: "",
                          },
                        },
                        rowHover: false,
                        setRowProps: (row, dataIndex, rowIndex) => {
                          return {
                            style: {
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#ffffff" : "#eeeeee",
                            },
                          };
                        },
                      }}
                    />
                  </ThemeProvider>
                </TabPanel>
                <TabPanel value="2">
                  <ThemeProvider
                    theme={createTheme({
                      components: {
                        ...getMuiTheme(),
                        MUIDataTableToolbar: {
                          styleOverrides: {
                            actions: {
                              display: "contents",
                            },
                          },
                        },
                      },
                    })}
                  >
                    <MUIDataTable
                      title={
                        <Row className="row">
                          <Col className="search-p">
                            <div className="me-2 mb-3">
                              <div className="position-relative sw">
                                <Input
                                  placeholder="Search"
                                  type="text"
                                  value={search}
                                  onChange={(e) => {
                                    handleSearch(e.target.value);
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="3"></Col>
                          <Col md="6" style={{ paddingRight: "unset" }}></Col>
                        </Row>
                      }
                      data={InitialData}
                      columns={columns}
                      key={Math.random()}
                      options={{
                        rowsPerPage: 50000, //InitialData.length/50000
                        print: false,
                        download: true,
                        filter: false,
                        selectableRows: "none",
                        search: false,
                        rowsPerPageOptions: [],
                        responsive:'scroll',
                        textLabels: {
                          body: {
                            noMatch: loading ? (
                              <div>
                                <Spinner
                                  color="primary"
                                  className="text-lbusers"
                                />
                              </div>
                            ) : (
                              "Sorry, no matching records found"
                            ),
                          },
                          viewColumns: {
                            title: "",
                          },
                        },
                        downloadOptions: {
                          filename: "glmapping.csv",
                        },
                        rowHover: false,
                        setRowProps: (row, dataIndex, rowIndex) => {
                          return {
                            style: {
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#ffffff" : "#eeeeee",
                            },
                          };
                        },
                        onDownload:( buildHead, buildBody, columns, data) =>{
                          try{
                            const formattedData = []
                            for(const row of data){
                              const newRow = [ ...row.data ];
                              newRow[8] = Number(newRow[8] ? newRow[8].replace(/,/g, '') : 0)
                              newRow[9] = Number(newRow[9] ? newRow[9].replace(/,/g, '') : 0)
                              newRow[11] = Number(newRow[11] ? newRow[11].replace(/,/g, '') : 0)
                              newRow[12] = Number(newRow[12] ? newRow[12].replace(/,/g, '') : 0)
                              formattedData.push({ ...row, data: newRow })
                            }
                            const csv = buildHead(columns) + buildBody(formattedData);
                            return csv
                          }catch(err){
                            console.log(err)
                            return buildHead(columns) + buildBody(data);
                          }                              
                        }
                      }}
                    />
                  </ThemeProvider>
                </TabPanel>
              </Col>
            </Row>
          </TabContext>
        </ThemeProvider>
      </Box>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(false);
          setType("");
          setTemplateData({});
        }}
        size="xl"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {type == "edit" ? "Edit" : "Add"} G/L Mapping (
            {moment(prop.jeDates && prop.jeDates[0] && prop.jeDates[0].start)
              .utc()
              .format("MM/DD/YYYY")}{" "}
            to {lastDate})
          </h5>
          <button
            type="button"
            onClick={() => {
              setModal(false);
              setType("");
              setTemplateData({});
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <Row>
              <Col lg={4} md={4} xl={4} xxl={4}>
                <div className="mb-2">
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    value={title}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} xl={4} xxl={4}>
                <select
                  className="form-control arrows w-100"
                  id="currency"
                  onChange={(e) => setSelectedGroup(e.target.value)}
                  value={selectedGroup}
                >
                  <option name="group" value="new">
                    Select template
                  </option>
                  {glGroups.map((opt) => (
                    <option key={opt._id} value={opt._id}>
                      {opt.title}
                    </option>
                  ))}
                </select>
              </Col>
              <Col lg={4} md={4} xl={4} xxl={4}>
                <InputGroup className="mb-2">
                  <Flatpickr
                    onClose={([date]) => {
                      if (date) {
                        setStartDate(moment(date).format("MM/DD/YYYY"));
                      }
                    }}
                    value={startDate}
                    className="form-control d-block bg-white"
                    placeholder="Select start date"
                    options={{
                      altInput: true,
                      altFormat: "m/d/Y",
                      dateFormat: "m/d/Y",
                      allowInput: true,
                      minDate: moment(
                        prop &&
                          prop.jeDates &&
                          prop.jeDates[0] &&
                          prop.jeDates[0].start
                      )
                        .utc()
                        .format("MM/DD/YYYY"),
                      maxDate: lastDate
                        ? lastDate
                        : moment(
                            prop &&
                              prop.jeDates &&
                              prop.jeDates[0] &&
                              prop.jeDates[0].end
                          )
                            .utc()
                            .format("MM/DD/YYYY"),
                      disable: templateDates ? templateDates : [],
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary docs-datepicker-trigger"
                      disabled
                    >
                      <i className="fa fa-calendar" aria-hidden="true" />
                    </button>
                  </div>
                </InputGroup>
              </Col>
              <Col lg={4} md={4} xl={4} xxl={4}>
                <InputGroup className="mb-2">
                  <Flatpickr
                    onClose={([date]) => {
                      if (date) {
                        setEndDate(moment(date).format("MM/DD/YYYY"));
                      }
                    }}
                    value={endDate}
                    className="form-control d-block bg-white"
                    placeholder="Select end date"
                    options={{
                      altInput: true,
                      altFormat: "m/d/Y",
                      dateFormat: "m/d/Y",
                      allowInput: true,
                      minDate: startDate
                        ? moment(startDate).add(1, "day").format("MM/DD/YYYY")
                        : moment(
                            prop &&
                              prop.jeDates &&
                              prop.jeDates[0] &&
                              prop.jeDates[0].start
                          )
                            .add(1, "day")
                            .utc()
                            .format("MM/DD/YYYY"),
                      maxDate: lastDate
                        ? lastDate
                        : moment(
                            prop &&
                              prop.jeDates &&
                              prop.jeDates[0] &&
                              prop.jeDates[0].end
                          )
                            .utc()
                            .format("MM/DD/YYYY"),
                      disable: templateDates ? templateDates : [],
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary docs-datepicker-trigger"
                      disabled
                    >
                      <i className="fa fa-calendar" aria-hidden="true" />
                    </button>
                  </div>
                </InputGroup>
              </Col>
            </Row>
            <GLMapping
              id={selectedGroup}
              type={type}
              templateData={templateData && templateData.glmapping}
              client_id={
                prop &&
                prop.data &&
                prop.data.client_id &&
                prop.data.client_id._id
              }
              lease_id={prop && prop.data && prop.data._id}
              glData={glData}
              setGlData={setGlData}
            />
            <Row>
              <Col>
                <button
                  type="button"
                  disabled={loading}
                  className="btn bg-theme-color w-md mt-2"
                  onClick={addLeaseTemplate}
                >
                  SAVE
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={deleteOpen}
        toggle={() => {
          setTemplateData({});
          setDeleteOpen(false);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Delete G/L Mapping</h5>
          <button
            type="button"
            onClick={() => {
              setTemplateData({});
              setDeleteOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              Are you sure you want to delete this G/L Mapping?
            </div>
            <div className="modal-footer mt-3">
              <button
                type="button"
                className="btn btn-lbusers"
                onClick={() => {
                  setTemplateData({});
                  setDeleteOpen(false);
                }}
                disabled={loading}
              >
                {loading && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
                No
              </button>
              <button
                type="button"
                className="btn btn-lbusers"
                disabled={loading}
                onClick={deleteLeaseTemplate}
              >
                {loading && (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                )}
                Yes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default GlMappingJE;
