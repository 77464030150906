import React, { useState, useEffect, useContext} from "react"
import {Helmet} from "react-helmet";
import { Card, CardBody, Col, Container, Form, Row, Spinner, TabPane, NavLink, NavItem, TabContent, Nav } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumbclientdetails";
import Filter from "pages/Filter";
import { AuthContext } from "context/authContext";
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import classnames from "classnames";
import {commaSeperated} from "helpers/formatters"
import { useLocation } from "react-router-dom";
import AccountingRollforward from "components/report/accounting";
import ExcelJS from "exceljs";
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Reports = () => {
    const location = useLocation();
    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    const { userData, reloadProcessingSearch, isProcessingSearch, mode } = useContext(AuthContext)
    const [filter, setFilter] = useState(true);
    const [loading, setLoading] = useState(false);
    const [lesseeClient, setLesseeClient] = useState([]);
    const [lessorClient, setLessorClient] = useState([]);
    const [operatingData, setOperatingData] = useState([]);
    const [financingData, setFinancingData] = useState([]);
    const [loadingReport, setLoadingReport] = useState(true);
    const [stLeaseData, setSTLeaseData] = useState([]);
    const [activeTab, setActiveTab] = useState("4");
    const [totalData, setTotalData] = useState({});
    const [searchResult, setSearchResult] = useState({});
    const [dateType, setDateType] = useState('exactDate');
    const [maturity,setMaturity] = useState([]);
    const [accountType, setAccountType] = useState("");
    const [operatingRollforward, setOperatingRollforward] = useState([]);
    const [financingRollforward, setFinancingRollforward] = useState([]);
    const [shortLeaseRollforward, setShortLeaseRollforward] = useState([]);
    const [opIndex, setOpIndex] = useState(0)
    const [fIndex, setFIndex] = useState(0)
    const [stIndex, setStIndex] = useState(0)

    const columns = [
        {
          label: 'Client Name',
          name: 'client',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>(
            <div style={{width:'200px', whiteSpace:'pre-wrap'}}>{value ? value : ''}</div>
            )
          }
        },
        {
          label: 'Lease Name',
          name: 'name',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>(
            <div style={{width:'200px', whiteSpace:'pre-wrap'}}>{value ? value : ''}</div>
            )
          }
        },
        {
          label: 'Lease Commencement',
          name: 'commencement',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>(
              value ? moment.utc(value).format('MM/DD/YYYY') : ''
            ),
          }
        },
        {
          label: 'Lease End Date',
          name: 'terminal_end_date',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>(
              value ? moment.utc(value).format('MM/DD/YYYY') : ''
            ),
          }
        },
        {
          label: `Per Schedule ${ mode == "lessor" ? "Deferred inflow of resources" : "ROU Assets"}`,
          name: 'rou_asset',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: mode == 'lessor' ? '250px' : '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: mode == 'lessor' ? '250px' : '200px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>(
              value ? commaSeperated(value) : 0
            ),
          }
        },
        {
          label: `Per Schedule ${ mode == "lessor" ? "Deferred inflow of resources Receivable" : "ROU Liability"}`,
          name: 'liability',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: mode == 'lessor' ? '250px' : '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: mode == 'lessor' ? '250px' : '200px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>(              
              value ? commaSeperated(value) : 0
            ),
          }
        },
        {
          label: `Per Schedule ${ mode == "lessor" ? "Deferred inflow of resources Receivable" : "ROU Liability"} After Year`,
          name: 'liability_1_year',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: mode == 'lessor' ? '350px' : '300px'}}),
            setCellHeaderProps: () => ({style: {minWidth: mode == 'lessor' ? '350px' : '300px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>(
              value ? commaSeperated(value) : 0
            ),
          }
        },
        {
          label: 'Weighted Avg',
          name: 'weighted_avg',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>{
              const rowIndex = tableMeta.rowIndex
              if(tableMeta && tableMeta.tableData && tableMeta.tableData[rowIndex] && tableMeta.tableData[rowIndex].classification === 'operating'){
                  return `${tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityOp ? commaSeperated((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityOp)*100) : 0}%`
              }
              else if(tableMeta.tableData[rowIndex] && tableMeta.tableData[rowIndex].classification === 'financing'){
                  return `${tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityF ? commaSeperated((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityF)*100) : 0}%`
              }
              else{
                  return '0.00%'
              }
            },
          }
        },
        {
          label: 'Lease Term at Commencement',
          name: 'lease_term',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '250px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>{
              const rowIndex = tableMeta.rowIndex
              return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' || tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? '' : value
            },
          }
        },
        {
          label: 'Remaining Term',
          name: 'remain_term',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>{
              const rowIndex = tableMeta.rowIndex
              return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' || tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? '' : value
            },
          }
        },
        {
          label: 'Weighted Avg Term',
          name: 'weighted_avg_term',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              const rowIndex = tableMeta.rowIndex
              if(tableMeta.tableData[rowIndex] && tableMeta.tableData[rowIndex].classification === 'operating'){
                  const avg = tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityOp ? commaSeperated((((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityOp)*100) * tableMeta.tableData[rowIndex].remain_term)/100) : 0
                  return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' ? commaSeperated(value) : avg 
              }
              else{
                  const avg = tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityF ? commaSeperated((((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityF)*100) * tableMeta.tableData[rowIndex].remain_term)/100) : 0
                  return tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? commaSeperated(value) : avg
              }
            },
          }
        },
        {
          label: 'Discount Rate',
          name: 'discount_rate',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=>{
              const rowIndex = tableMeta.rowIndex
              return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' || tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? '' : value ? `${commaSeperated(value)}%` : ''
            },
          }
        },
        {
          label: 'Weighted Avg Rate',
          name: 'weighted_avg_rate',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              const rowIndex = tableMeta.rowIndex
              if(tableMeta.tableData[rowIndex] && tableMeta.tableData[rowIndex].classification === 'operating'){
                  const avg = tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityOp ? commaSeperated((((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityOp)*100) * tableMeta.tableData[rowIndex].discount_rate)/100) : 0
                  return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' ? `${commaSeperated(value)}%` : `${avg}%`
              }
              else{
                  const avg = tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityF ? commaSeperated((((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityF)*100) * tableMeta.tableData[rowIndex].discount_rate)/100) : 0
                  return tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? `${commaSeperated(value)}%` : `${avg}%`
              }
            },
          }
        },
        {
          label: `Year 1 (${(maturity && maturity[0] && maturity[0].year) || ''})`,
          name: 'maturity',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              return commaSeperated((value && value[0] && value[0].data) || 0)
            },
          }
        },
        {
          label: `Year 2 (${(maturity && maturity[1] && maturity[1].year) || ''})`,
          name: 'maturity',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              return commaSeperated((value && value[1] && value[1].data) || 0)
            },
          }
        },
        {
          label: `Year 3 (${(maturity && maturity[2] && maturity[2].year) || ''})`,
          name: 'maturity',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              return commaSeperated((value && value[2] && value[2].data) || 0)
            },
          }
        },
        {
          label: `Year 4 (${(maturity && maturity[3] && maturity[3].year) || ''})`,
          name: 'maturity',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              return commaSeperated((value && value[3] && value[3].data) || 0)
            },
          }
        },
        {
          label: `Year 5 (${(maturity && maturity[4] && maturity[4].year) || ''})`,
          name: 'maturity',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              return commaSeperated((value && value[4] && value[4].data) || 0)
            },
          }
        },
        {
          label: 'Thereafter',
          name: 'maturity',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRender:(value, tableMeta, updateValue)=> {
              return commaSeperated((value && value[5] && value[5].data) || 0)
            },
          }
        },
    ]

    const stColumns = [
      {
        label: 'Client Name',
        name: 'client',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>(
          <div style={{width:'200px', whiteSpace:'pre-wrap'}}>{value ? value : ''}</div>
          )
        }
      },
      {
        label: 'Lease Name',
        name: 'name',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>(
          <div style={{width:'200px', whiteSpace:'pre-wrap'}}>{value ? value : ''}</div>
          )
        }
      },
      {
        label: 'Lease Commencement',
        name: 'commencement',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>(
            value ? moment.utc(value).format('MM/DD/YYYY') : ''
          ),
        }
      },
      {
        label: 'Lease End Date',
        name: 'terminal_end_date',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>(
            value ? moment.utc(value).format('MM/DD/YYYY') : ''
          ),
        }
      },
      {
        label: `Per Schedule Lease Asset-Liability`,
        name: 'differed_value',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '300px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '300px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>{
            const rowIndex = tableMeta.rowIndex
            return Number(Number(tableMeta.tableData[rowIndex].rou_asset - tableMeta.tableData[rowIndex].liability).toFixed(2)) != 0 ? commaSeperated(tableMeta.tableData[rowIndex].rou_asset - tableMeta.tableData[rowIndex].liability) : 0
          }
        }
      },
      {
        label: `Per Schedule Lease Asset-Liability After Year`,
        name: 'liability_1_year',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '350px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '350px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>(
            value && Number(Number(value).toFixed(2)) != 0 ? commaSeperated(value) : 0
          ),
        }
      },
      {
        label: 'Weighted Avg',
        name: 'weighted_avg',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>{
            const rowIndex = tableMeta.rowIndex
            if(tableMeta && tableMeta.tableData && tableMeta.tableData[rowIndex] && tableMeta.tableData[rowIndex].classification === 'operating'){
                return `${tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityST ? commaSeperated((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityST)*100) : 0}%`
            } else{
                return '0.00%'
            }
          },
        }
      },
      {
        label: 'Lease Term at Commencement',
        name: 'lease_term',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '250px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>{
            const rowIndex = tableMeta.rowIndex
            return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' || tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? '' : value
          },
        }
      },
      {
        label: 'Remaining Term',
        name: 'remain_term',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>{
            const rowIndex = tableMeta.rowIndex
            return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' || tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? '' : value
          },
        }
      },
      {
        label: 'Weighted Avg Term',
        name: 'weighted_avg_term',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            const rowIndex = tableMeta.rowIndex
            if(tableMeta.tableData[rowIndex] && tableMeta.tableData[rowIndex].classification === 'operating'){
                const avg = tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityST ? commaSeperated((((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityST)*100) * tableMeta.tableData[rowIndex].remain_term)/100) : 0
                return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' && Number(Number(tableMeta.tableData[rowIndex].weighted_avg_term).toFixed(2)) != 0 ? commaSeperated(tableMeta.tableData[rowIndex].weighted_avg_term) : avg 
            }
          },
        }
      },
      {
        label: 'Discount Rate',
        name: 'discount_rate',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=>{
            const rowIndex = tableMeta.rowIndex
            return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' || tableMeta.tableData[rowIndex].name === 'Total Financing Leases' ? '' : value && value > 0 ? `${commaSeperated(value)}%` : ''
          },
        }
      },
      {
        label: 'Weighted Avg Rate',
        name: 'weighted_avg_rate',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            const rowIndex = tableMeta.rowIndex
            if(tableMeta.tableData[rowIndex] && tableMeta.tableData[rowIndex].classification === 'operating'){
                const avg = tableMeta.tableData[rowIndex].liability && totalData.totalLiabilityST ? commaSeperated((((tableMeta.tableData[rowIndex].liability/totalData.totalLiabilityST)*100) * tableMeta.tableData[rowIndex].discount_rate)/100) : 0
                return tableMeta.tableData[rowIndex].name === 'Total Operating Leases' && value > 0 ? `${commaSeperated(value)}%` : `${avg}%`
            }
          },
        }
      },
      {
        label: `Year 1 (${(maturity && maturity[0] && maturity[0].year) || ''})`,
        name: 'maturity',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            return commaSeperated((value && value[0] && value[0].data) || 0)
          },
        }
      },
      {
        label: `Year 2 (${(maturity && maturity[1] && maturity[1].year) || ''})`,
        name: 'maturity',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            return commaSeperated((value && value[1] && value[1].data) || 0)
          },
        }
      },
      {
        label: `Year 3 (${(maturity && maturity[2] && maturity[2].year) || ''})`,
        name: 'maturity',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            return commaSeperated((value && value[2] && value[2].data) || 0)
          },
        }
      },
      {
        label: `Year 4 (${(maturity && maturity[3] && maturity[3].year) || ''})`,
        name: 'maturity',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            return commaSeperated((value && value[3] && value[3].data) || 0)
          },
        }
      },
      {
        label: `Year 5 (${(maturity && maturity[4] && maturity[4].year) || ''})`,
        name: 'maturity',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            return commaSeperated((value && value[4] && value[4].data) || 0)
          },
        }
      },
      {
        label: 'Thereafter',
        name: 'maturity',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRender:(value, tableMeta, updateValue)=> {
            return commaSeperated((value && value[5] && value[5].data) || 0)
          },
        }
      },
    ]

    useEffect(() => {
      if(financingData.length > 0){
        let f = financingData.filter(v => v.name === 'Total Financing Leases')
        setMaturity(f && f.length > 0 ? f[0].maturity : [])
      }else if(operatingData.length > 0){
        let o = operatingData.filter(v => v.name === 'Total Operating Leases')
        setMaturity(o && o.length > 0 ? o[0].maturity : [])
      }else if(stLeaseData.length > 0){
        let st = stLeaseData.filter(v => v.name === 'Total ST Leases')
        setMaturity(st && st.length > 0 ? st[0].maturity : [])
      }else{
        setMaturity([])
      }
    },[financingData, operatingData, stLeaseData, totalData])

    const getClientsData = async(filter) =>{
        setLesseeClient([]);
        setLessorClient([]);
        setLoading(true);
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&access_type=${filter.access_type || ""}&search=${filter.search || ""}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
              const lessee = data.clientData && data.clientData.filter(l => l.client_id.client_type == 'lessee')
              setLesseeClient(lessee);
              const lessor = data.clientData && data.clientData.filter(l => l.client_id.client_type == 'lessor')
              setLessorClient(lessor);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    const getSearchResult = async(id, type) => {
        setLoadingReport(true);
        setFilter(false)
        setTotalData({});
        setOperatingData([]);
        setFinancingData([]);
        setSTLeaseData([]);
        setFinancingRollforward([]);
        setOperatingRollforward([]);
        setShortLeaseRollforward([])
        try {
          const {data} = await axios.get(`${AppConfig.baseUrl}/search_query?id=${id}&type=${type}&mode=${mode ? mode : 'lessee'}`,{
              headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
              setAccountType(data.searchResult && data.searchResult.report_type)
              setSearchResult(data.searchResult)
              setTotalData(data.searchResult && data.searchResult.result && data.searchResult.result.totalData && Object.keys(data.searchResult.result.totalData).length > 0 ? data.searchResult.result.totalData : {});
              setOperatingData(data.searchResult && data.searchResult.result && data.searchResult.result.operating && data.searchResult.result.operating.length > 0 ? data.searchResult.result.operating : []);
              setFinancingData(data.searchResult && data.searchResult.result && data.searchResult.result.financing && data.searchResult.result.financing.length > 0 ? data.searchResult.result.financing : []);
              setSTLeaseData(data.searchResult && data.searchResult.result && data.searchResult.result.stData && data.searchResult.result.stData.length > 0 ? data.searchResult.result.stData : []);
              setOperatingRollforward(data.searchResult && data.searchResult.result && data.searchResult.result.operating && data.searchResult.result.operating.length > 0 ? data.searchResult.result.operating : []);
              setFinancingRollforward(data.searchResult && data.searchResult.result && data.searchResult.result.financing && data.searchResult.result.financing.length > 0 ? data.searchResult.result.financing : []);
              setShortLeaseRollforward(data.searchResult && data.searchResult.result && data.searchResult.result.stData && data.searchResult.result.stData.length > 0 ? data.searchResult.result.stData : []);
              setOpIndex(data.searchResult && data.searchResult.result && data.searchResult.result.operating && data.searchResult.result.operating.length > 0 ? data.searchResult.result.operating.length - 1 : 1);
              setFIndex(data.searchResult && data.searchResult.result && data.searchResult.result.financing && data.searchResult.result.financing.length > 0 ? data.searchResult.result.financing.length - 1 : 1);
              setStIndex(data.searchResult && data.searchResult.result && data.searchResult.result.stData && data.searchResult.result.stData.length > 0 ? data.searchResult.result.stData.length - 1 : 1);
              
              if(data.searchResult && data.searchResult.result && data.searchResult.result.operating && data.searchResult.result.operating.length > 0){
                setActiveTab("1")
              }else if(data.searchResult && data.searchResult.result && data.searchResult.result.financing && data.searchResult.result.financing.length > 0){
                  setActiveTab("2")
              }else if(data.searchResult && data.searchResult.result && data.searchResult.result.stData && data.searchResult.result.stData.length > 0){
                setActiveTab("3")
              }else{
                setActiveTab("4")
              }
            }
          else{
              throw new Error(data.title);
          }
        } catch (error) {
          toast.error(error.message || 'Something went wrong');
        }
        setLoadingReport(false);
    }
    
    const getReportData = async(start, leases,clients, weekType, dayType, monthType, yearDate, dateType) =>{
        setLoadingReport(true); 
        setTotalData({});
        setOperatingData([]);
        setFinancingData([]);
        setSTLeaseData([]);
        try {
            const body = {
                date: moment(start).format("YYYY-MM-DD"),
                lease_ids: leases,
                clients,
                weekType, dayType, monthType, yearDate, dateType,
                mode: mode ? mode : 'lessee'
            }
            const {data} = await axios.post(`${AppConfig.baseUrl}/report/get_reports`,body, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                getSearchResult(data.searchData._id);
                reloadProcessingSearch();
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            setLoadingReport(false)
        }
    }

    const getRollforwardData = async(startDate, endDate, leases, clients,yearDate) =>{
      setLoadingReport(true);
      setFinancingRollforward([]);
      setOperatingRollforward([]);
      setShortLeaseRollforward([])
      try {
          const body = {
              start: moment(startDate).format("YYYY-MM-DD"),
              end: moment(endDate).format("YYYY-MM-DD"),
              lease_ids: leases,
              clients,
              yearDate,
              mode: mode ? mode : 'lessee'
          }
          const {data} = await axios.post(`${AppConfig.baseUrl}/report/get_rollforward`,body, {
              headers: { token: localStorage.getItem("token") }
          });
          if(!data.error){
              setSearchResult(data.searchData);
              getSearchResult(data.searchData._id)
              reloadProcessingSearch();
          }
          else{
              throw new Error(data.title);
          }
      } catch (error) {
          toast.error(error.message || 'Something went wrong');
          setLoadingReport(false)
      }
    }
  
    useEffect(()=>{
        if(sp.get("id")){
        setFilter(false);
        getSearchResult(sp.get("id"), 'delete')
        }
    },[location.search])

    useEffect(()=>{
        if(searchResult && searchResult._id && !isProcessingSearch){
          getSearchResult(searchResult._id, 'delete')
        }
    },[isProcessingSearch])

    useEffect(()=>{
      if(mode && !filter){
        setFilter(true)
        setOperatingData([]);
        setFinancingData([]);
        setSTLeaseData([]);
        setFinancingRollforward([]);
        setShortLeaseRollforward([]);
        setOperatingRollforward([]);
      }
    },[mode])

    const handleChangeType = (e) => {
        setDateType(e.target.value)
    }

    useEffect(() => {
        if(userData && userData.id){
            getClientsData({});
        }
    }, [userData])

    const flip = (selectedLeases, startDate, endDate, jeType, dateType, weekType, dayType, monthType, yearDate) => {
        let uniqueClients = [];
        let ids = []
        for(const lease of selectedLeases){
            ids.push(lease._id)
            const client = lease.client_id
            const index = uniqueClients.findIndex(v => v === lease.client_id._id)
            if(index === -1){
                uniqueClients.push(client._id);
            }
        }
        setFilter(false);
        if(accountType && accountType == 'rollforward'){
          getRollforwardData(startDate, endDate, ids, uniqueClients)
        }else{
          getReportData(startDate, ids, uniqueClients, weekType, dayType, monthType, yearDate, dateType);
        }
    }

    const downloadCSVFile = (type) => {
        var ExcelJSWorkbook = new ExcelJS.Workbook();
        var worksheet1 = ExcelJSWorkbook.addWorksheet("Report criteria");
        worksheet1.getColumn("A").width = 40
        worksheet1.getColumn("B").width = 40
        worksheet1.getCell("A1").value = `Report Type`
        worksheet1.getCell("B1").value = 'Accounting snapshot'
        worksheet1.getCell("A2").value = `Date`
        worksheet1.getCell("B2").value = new Date(searchResult.date)
        worksheet1.getCell("B2").numFmt = 'mm/dd/yyyy'
        worksheet1.getCell("A3").value = `Date Type`
        worksheet1.getCell("B3").value = dateType == 'description' ? 'Year end - date from description (beta)' : 'Year end - exact date'
        worksheet1.getCell("A4").value = `Year End Date`
        worksheet1.getCell("B4").value = searchResult.year_end_date

        var worksheet = ExcelJSWorkbook.addWorksheet("Accounting snapshot");
        worksheet.getColumn("A").width = 40
        worksheet.getColumn("B").width = 40
        worksheet.getColumn("C").width = 40
        worksheet.getColumn("D").width = 20
        worksheet.getColumn("E").width = 20
        worksheet.getColumn("F").width = 20
        worksheet.getColumn("G").width = 20
        worksheet.getColumn("H").width = 20
        worksheet.getColumn("I").width = 20
        worksheet.getColumn("J").width = 20
        worksheet.getColumn("K").width = 20
        worksheet.getColumn("L").width = 20
        worksheet.getColumn("M").width = 20
        worksheet.getColumn("N").width = 20
        worksheet.getColumn("O").width = 20
        worksheet.getColumn("P").width = 20
        worksheet.getColumn("Q").width = 20
        worksheet.getColumn("R").width = 20
        worksheet.getColumn("S").width = 20
      
        worksheet.getCell("A1").value = `Client Name`        
        worksheet.getCell("B1").value = `Lease Name`        
        worksheet.getCell("C1").value = `Lease Commencement`        
        worksheet.getCell("D1").value = `Lease End Date`  
        if(type == 'st_lease'){          
          worksheet.getCell("E1").value = `Per Schedule Lease Asset-Liability`          
          worksheet.getCell("F1").value = `Per Schedule Lease Asset-Liability After Year`
        }else{
          worksheet.getCell("E1").value = `Per Schedule ${ mode == "lessor" ? "Deferred inflow of resources" : "ROU Assets"}`        
          worksheet.getCell("F1").value = `Per Schedule ${ mode == "lessor" ? "Deferred inflow of resources Receivable" : "ROU Liability"}`   
          worksheet.getCell("G1").value = `Per Schedule ${ mode == "lessor" ? "Deferred inflow of resources Receivable" : "ROU Liability"} After Year`
        }
        worksheet.getCell(type == 'st_lease' ? "G1" : "H1").value = `Weighted Avg`
        worksheet.getCell(type == 'st_lease' ? "H1" : "I1").value = `Lease Term at Commencement`
        worksheet.getCell(type == 'st_lease' ? "I1" : "J1").value = `Remaining Term`
        worksheet.getCell(type == 'st_lease' ? "J1" : "K1").value = `Weighted Avg Term`
        worksheet.getCell(type == 'st_lease' ? "K1" : "L1").value = `Discount Rate`
        worksheet.getCell(type == 'st_lease' ? "L1" : "M1").value = `Weighted Avg Rate`
        worksheet.getCell(type == 'st_lease' ? "M1" : "N1").value = `Year 1 (${maturity && maturity[0] && maturity[0].year || ''})`
        worksheet.getCell(type == 'st_lease' ? "N1" : "O1").value = `Year 2 (${maturity && maturity[1] && maturity[1].year || ''})`
        worksheet.getCell(type == 'st_lease' ? "O1" : "P1").value = `Year 3 (${maturity && maturity[2] && maturity[2].year || ''})`
        worksheet.getCell(type == 'st_lease' ? "P1" : "Q1").value = `Year 4 (${maturity && maturity[3] && maturity[3].year || ''})`
        worksheet.getCell(type == 'st_lease' ? "Q1" : "R1").value = `Year 5 (${maturity && maturity[4] && maturity[4].year || ''})`
        worksheet.getCell(type == 'st_lease' ? "R1" : "S1").value = `Thereafter`

        if(type == 'st_lease'){            
          stLeaseData && stLeaseData.length > 0 && stLeaseData.map((value, i) => {
            const avg1 = value.liability && totalData.totalLiabilityST ? (((value.liability/totalData.totalLiabilityST)*100) * value.remain_term)/100 : 0                
            const avg = value.liability && totalData.totalLiabilityST ? (((value.liability/totalData.totalLiabilityST)*100) * value.discount_rate)/100 : 0
            worksheet.addRow([ value.client, value.name, value.commencement ? new Date(value.commencement) : '', value.terminal_end_date ? new Date(value.terminal_end_date) : '', (value.rou_asset - value.liability), value.liability_1_year ? value.liability_1_year : '', value.liability && totalData.totalLiabilityST ? (value.liability/totalData.totalLiabilityST) : 0, value.lease_term, value.remain_term, value.name === 'Total Operating Leases' ? value.weighted_avg_term : avg1, (value.discount_rate)/100, value.name === 'Total Operating Leases' ? (value.weighted_avg_rate)/100 : (avg)/100, value.maturity && value.maturity[0] && value.maturity[0].data || 0, value.maturity && value.maturity[1] && value.maturity[1].data || 0, value.maturity && value.maturity[2] && value.maturity[2].data || 0, value.maturity && value.maturity[3] && value.maturity[3].data || 0, value.maturity && value.maturity[4] && value.maturity[4].data || 0, value.maturity && value.maturity[5] && value.maturity[5].data || 0]);
          })
        }else if(type == 'operating'){            
            operatingData && operatingData.length > 0 && operatingData.map((value, i) => {
              const avg1 = value.liability && totalData.totalLiabilityOp ? (((value.liability/totalData.totalLiabilityOp)*100) * value.remain_term)/100 : 0                
              const avg = value.liability && totalData.totalLiabilityOp ? (((value.liability/totalData.totalLiabilityOp)*100) * value.discount_rate)/100 : 0
              worksheet.addRow([ value.client, value.name, value.commencement ? new Date(value.commencement) : '', value.terminal_end_date ? new Date(value.terminal_end_date) : '', value.rou_asset, value.liability, value.liability_1_year ? value.liability_1_year : '', value.liability && totalData.totalLiabilityOp ? (value.liability/totalData.totalLiabilityOp) : 0, value.lease_term, value.remain_term, value.name === 'Total Operating Leases' ? value.weighted_avg_term : avg1, (value.discount_rate)/100, value.name === 'Total Operating Leases' ? (value.weighted_avg_rate)/100 : (avg)/100, value.maturity && value.maturity[0] && value.maturity[0].data || 0, value.maturity && value.maturity[1] && value.maturity[1].data || 0, value.maturity && value.maturity[2] && value.maturity[2].data || 0, value.maturity && value.maturity[3] && value.maturity[3].data || 0, value.maturity && value.maturity[4] && value.maturity[4].data || 0, value.maturity && value.maturity[5] && value.maturity[5].data || 0]);
            })
        }else{            
            financingData && financingData.length > 0 && financingData.map((value, i) => {
              const avg1 = value.liability && totalData.totalLiabilityF ? (((value.liability/totalData.totalLiabilityF)*100) * value.remain_term)/100 : 0
              const avg = value.liability && totalData.totalLiabilityF ? (((value.liability/totalData.totalLiabilityF)*100) * value.discount_rate)/100 : 0
              worksheet.addRow([ value.client, value.name, value.commencement ? new Date(value.commencement) : '', value.terminal_end_date ? new Date(value.terminal_end_date) : '', value.rou_asset, value.liability, value.liability_1_year ? value.liability_1_year : '', value.liability && totalData.totalLiabilityF ? (value.liability/totalData.totalLiabilityF) : 0, value.lease_term, value.remain_term, value.name === 'Total Financing Leases' ? value.weighted_avg_term : avg1, (value.discount_rate)/100, value.name === 'Total Financing Leases' ? (value.weighted_avg_rate)/100 : (avg)/100, value.maturity && value.maturity[0] && value.maturity[0].data || 0, value.maturity && value.maturity[1] && value.maturity[1].data || 0, value.maturity && value.maturity[2] && value.maturity[2].data || 0, value.maturity && value.maturity[3] && value.maturity[3].data || 0, value.maturity && value.maturity[4] && value.maturity[4].data || 0, value.maturity && value.maturity[5] && value.maturity[5].data || 0]);
            }) 
        }
        
        worksheet.getColumn(3).numFmt = 'mm/dd/yyyy'
        worksheet.getColumn(4).numFmt = 'mm/dd/yyyy'
        worksheet.getColumn(5).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(6).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(7).numFmt = type == 'st_lease' ? '0.00%' : '##,##,##,##,##0.00'
        worksheet.getColumn(8).numFmt = type == 'st_lease' ? '' : '0.00%'
        worksheet.getColumn(9).numFmt = ''
        worksheet.getColumn(10).numFmt = ''
        worksheet.getColumn(11).numFmt = type == 'st_lease' ? '0.00%' : '##,##,##,##,##0.00' 
        worksheet.getColumn(12).numFmt = '0.00%' 
        worksheet.getColumn(13).numFmt = type == 'st_lease' ? '##,##,##,##,##0.00' : '0.00%'
        worksheet.getColumn(14).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(15).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(16).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(17).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(18).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(19).numFmt = '##,##,##,##,##0.00'

        ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
            var csv_file, download_link;
            csv_file = new Blob([buffer], { type: "application/octet-stream" });
            download_link = document.createElement("a");
            download_link.download = type == 'st_lease' ? "st_lease_reports.xlsx" : type == 'operating' ? "operating_lease_reports.xlsx" : "financing_lease_reports.xlsx" ;
            download_link.href = window.URL.createObjectURL(csv_file);
            download_link.style.display = "none";
            document.body.appendChild(download_link);
            download_link.click();
        });
    }

    return (
        <Layout>
            {filter ?
                <div className="page-content">
                    <Helmet>
                        <title>LeaseJava | Reports</title>
                    </Helmet>
                    <Container fluid>
                        <Breadcrumbs title="reports_filter"
                            name="Reports"
                            type="reports_filter"
                        />
                    </Container>
                    <Filter handleClick={flip} type="reports" clientsData={mode == 'lessor' ? lessorClient : lesseeClient} accountType={accountType} setAccountType={setAccountType} loading={loading} getSearchResult={getSearchResult} dateType={dateType} setDateType={setDateType} handleChangeType={handleChangeType}/> 
                </div>
                :
                <div className="page-content">
                    <Helmet>
                        <title>LeaseJava | Reports</title>
                    </Helmet>
                    <Container fluid>
                        <Breadcrumbs title="reports"
                            name1="Reports"
                            type="reports"
                        />
                        {searchResult && Object.keys(searchResult).length > 0 &&
                            <Row>
                                <Col md="6">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs="4">
                                                    <h6>{ accountType && accountType == 'rollforward' ? `Date range: ` : `Date: `}</h6>
                                                </Col>
                                                { accountType && accountType == 'rollforward' ?
                                                  <>
                                                    <Col xs="8" style={{textAlign: 'right'}}>
                                                      {moment(searchResult.startDate).utc().format('MM/DD/YYYY')}-{moment(searchResult.endDate).utc().format('MM/DD/YYYY')} 
                                                    </Col>
                                                  </>
                                                  :
                                                  <>
                                                    <Col xs="8" style={{textAlign: 'right'}}>
                                                      {moment(searchResult.date).utc().format('MM/DD/YYYY')}
                                                    </Col>
                                                  </>
                                                }
                                            </Row>
                                            { !accountType &&
                                              <Row>
                                                  <Col xs="4">
                                                      <h6>Year End:</h6>
                                                  </Col>
                                                  <Col xs="8" style={{textAlign: 'right'}}>
                                                      {searchResult.year_end_date}
                                                  </Col>
                                              </Row>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs="4">
                                                    <h6>Clients:</h6>
                                                </Col>
                                                <Col xs="8" style={{textAlign: 'right'}}>
                                                    {searchResult.clients && searchResult.clients.length > 1 ? searchResult.clients[0].client+", "+searchResult.clients[1].client+(searchResult.clients.length > 2 ? " + "+(searchResult.clients.length - 2)+" others" : '') : searchResult.clients && searchResult.clients.length > 0 ? searchResult.clients[0].client : ''}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="4">
                                                    <h6>Leases:</h6>
                                                </Col>
                                                <Col xs="8" style={{textAlign: 'right'}}>
                                                    {searchResult.lease_ids && searchResult.lease_ids.length > 1 ? searchResult.lease_ids[0].name+", "+searchResult.lease_ids[1].name+(searchResult.lease_ids.length > 2 ? " + "+(searchResult.lease_ids.length - 2)+" others" : '') : searchResult.lease_ids && searchResult.lease_ids.length > 0 ? searchResult.lease_ids[0].name : ''}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row>
                          <Col>
                            <Card>
                                <CardBody>
                                { accountType && accountType == 'rollforward' ? 
                                    <AccountingRollforward operatingRollforward={operatingRollforward} financingRollforward={financingRollforward} shortLeaseRollforward={shortLeaseRollforward} loadingReport={loadingReport} searchResult={searchResult}/>
                                  : 
                                  <>
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        {
                                            operatingData.length > 0 &&
                                            <NavItem style={{textAlign: 'left'}}>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        setActiveTab("1");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="fas fa-home"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block font-size-16">OPERATING LEASES</span>
                                                </NavLink>
                                            </NavItem>
                                        }
                                        {
                                            financingData.length > 0 &&
                                            <NavItem style={{textAlign: 'left'}}>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "2",
                                                    })}
                                                    onClick={() => {
                                                    setActiveTab("2");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="far fa-envelope"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block font-size-16">FINANCING LEASES</span>
                                                </NavLink>
                                            </NavItem>
                                        }
                                        {
                                            stLeaseData.length > 0 &&
                                            <NavItem style={{textAlign: 'left'}}>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "3",
                                                    })}
                                                    onClick={() => {
                                                        setActiveTab("3");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="fas fa-home"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block font-size-16">ST LEASES</span>
                                                </NavLink>
                                            </NavItem>
                                        }
                                        {
                                            operatingData.length < 1 && financingData.length < 1 && stLeaseData < 1 &&
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "4",
                                                    })}
                                                    onClick={() => {
                                                    setActiveTab("3");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="far fa-envelope"></i>
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        }
                                    </Nav>
                                    <TabContent
                                        activeTab={activeTab}
                                        className="p-3 text-muted"
                                    >
                                        <TabPane tabId="1">
                                          <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                            MUIDataTableToolbar:{
                                              styleOverrides:{
                                                actions: {
                                                  display: 'contents'
                                                }
                                              }
                                            }
                                          }})}>
                                            <MUIDataTable
                                              title={
                                                <Form>
                                                  <Row>
                                                    <Col xs="12">
                                                        <div className="mb-4" style={{float:'right',marginRight:'-7px'}}>
                                                            <button type="button" disabled={loadingReport} onClick={()=>{downloadCSVFile('operating')}} className="btn btn-lbusers btn-md">
                                                                {
                                                                    loadingReport && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                                }
                                                                EXPORT CSV
                                                            </button>&nbsp;&nbsp;
                                                          </div>
                                                    </Col>
                                                  </Row>
                                                </Form>
                                              }
                                              data={operatingData}
                                              columns={columns}
                                              options={{ 
                                                rowsPerPage:10, 
                                                print:false, 
                                                download:false, 
                                                filter:false, 
                                                selectableRows:"none", 
                                                search:false, 
                                                pagination:false,
                                                rowsPerPageOptions:[], 
                                                responsive:'scroll',
                                                textLabels: {
                                                  viewColumns: {
                                                    title: "",
                                                  },
                                                },
                                                rowHover: false,
                                                setRowProps: (row, dataIndex, rowIndex) => {
                                                  return {
                                                    style: { backgroundColor: rowIndex === opIndex ? "#edf0f5" : rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee', position: rowIndex == opIndex ? 'sticky' : '', bottom: rowIndex == opIndex ? 0 : ''},
                                                  };
                                                },
                                              }}
                                            />
                                          </ThemeProvider>
                                        </TabPane>
                                        <TabPane tabId="2">
                                          <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                            MUIDataTableToolbar:{
                                              styleOverrides:{
                                                actions: {
                                                  display: 'contents'
                                                }
                                              }
                                            }
                                          }})}>
                                            <MUIDataTable
                                              title={
                                                <Form>
                                                  <Row>
                                                      <Col xs="12">
                                                          <div className="mb-4" style={{float:'right',marginRight:'-7px'}}>
                                                              <button type="button" disabled={loadingReport} onClick={()=>{downloadCSVFile('financing')}} className="btn btn-lbusers btn-md">
                                                                  {
                                                                      loadingReport && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                                  }
                                                                  EXPORT CSV
                                                              </button>&nbsp;&nbsp;
                                                            </div>
                                                      </Col>
                                                  </Row>
                                                </Form>
                                              }
                                              data={financingData}
                                              columns={columns}
                                              options={{ 
                                                rowsPerPage:10, 
                                                print:false, 
                                                download:false, 
                                                filter:false, 
                                                selectableRows:"none", 
                                                search:false, 
                                                pagination:false,
                                                rowsPerPageOptions:[], 
                                                responsive:'scroll',
                                                textLabels: {
                                                  viewColumns: {
                                                    title: "",
                                                  },
                                                },
                                                rowHover: false,
                                                setRowProps: (row, dataIndex, rowIndex) => {
                                                  return {
                                                    style: { backgroundColor: rowIndex === fIndex ? "#edf0f5" : rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee', position: rowIndex == fIndex ? 'sticky' : '', bottom: rowIndex == fIndex ? 0 : ''},
                                                  };
                                                },
                                              }}
                                            />
                                          </ThemeProvider>
                                        </TabPane>
                                        <TabPane tabId="3">
                                          <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                            MUIDataTableToolbar:{
                                              styleOverrides:{
                                                actions: {
                                                  display: 'contents'
                                                }
                                              }
                                            }
                                          }})}>
                                            <MUIDataTable
                                              title={
                                                <Form>
                                                  <Row>
                                                      <Col xs="12">
                                                          <div className="mb-4" style={{float:'right',marginRight:'-7px'}}>
                                                              <button type="button" disabled={loadingReport} onClick={()=>{downloadCSVFile('financing')}} className="btn btn-lbusers btn-md">
                                                                  {
                                                                      loadingReport && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                                  }
                                                                  EXPORT CSV
                                                              </button>&nbsp;&nbsp;
                                                            </div>
                                                      </Col>
                                                  </Row>
                                                </Form>
                                              }
                                              data={stLeaseData}
                                              columns={stColumns}
                                              options={{ 
                                                rowsPerPage:10, 
                                                print:false, 
                                                download:false, 
                                                filter:false, 
                                                selectableRows:"none", 
                                                search:false, 
                                                pagination:false,
                                                rowsPerPageOptions:[], 
                                                responsive:'scroll',
                                                textLabels: {
                                                  viewColumns: {
                                                    title: "",
                                                  },
                                                },
                                                rowHover: false,
                                                setRowProps: (row, dataIndex, rowIndex) => {
                                                  return {
                                                    style: { backgroundColor: rowIndex === stIndex ? "#edf0f5" : rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee', position: rowIndex == stIndex ? 'sticky' : '', bottom: rowIndex == stIndex ? 0 : ''},
                                                  };
                                                },
                                              }}
                                            />
                                          </ThemeProvider>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <div className="text-center mt-4">
                                                  {
                                                      loadingReport ? <Spinner color="primary" className="text-lbusers" /> : !loadingReport && searchResult && searchResult.is_processing ? <div><h6>Your data is being processed</h6><Spinner color="primary" className="text-lbusers" /></div> : "No data found"
                                                  }
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                  </>
                                }
                                </CardBody>
                            </Card>
                          </Col>
                        </Row>
                    </Container>
                </div>
            }
        </Layout>
    )
}

export default Reports