import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col } from "reactstrap"

const Breadcrumb = prop => {

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">
                {prop.breadcrumbItem}
            </h4>
            {
              prop.type === 'batch_details' ? 
                <Link to={`/${localStorage.getItem('company')}/bulk_upload/logs/${prop.batch_id}/${prop.mode}`} className="btn btn-lbusers">
                  View Log
                </Link>              
              : ""
            }
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
